/**
 * @fileoverview Include all jQuery plugins needed by STORM_ENTRY_POINTS
 */
require('jquery-ui/ui/widgets/autocomplete');
require('jquery-ui/ui/widgets/datepicker');
require('jquery-ui/ui/widgets/dialog');
require('jquery-ui/ui/widgets/draggable');
require('jquery-ui/ui/widgets/sortable');
require('jquery-ui/ui/widgets/tooltip');
require('jquery.dynatree/dist/jquery.dynatree');
require('blueimp-file-upload');
require('cropper');

/**
 * @param {*} s
 * @return {boolean}
 */
function isString(s) {
  return typeof s === 'string';
}

/**
 * Add highcharts method to jQuery
 *
 * Highcharts adds this method to the global jQuery so we need to add it to our
 * bundled jQuery for everything to work as our existing code expects.
 *
 * This code is taken from Highcharts:
 * https://github.com/highcharts/highcharts/blob/v7.1.3/ts/parts/Utilities.ts#L3282
 *
 * @param {...?} args
 * @return {?}
 * @suppress {globalThis}
 */
jQuery.fn.highcharts = function(...args) {
  if (this[0]) { // this[0] is the renderTo div
    // Create the chart
    if (args[0]) {
      new Highcharts[ // eslint-disable-line
        // Constructor defaults to Chart
        isString(args[0]) ? args.shift() : 'Chart'
      ](this[0], args[0], args[1]);
      return this;
    }
    // When called without parameters or with the return argument,
    // return an existing chart
    return Highcharts['charts'][Highcharts['attr'](this[0], 'data-highcharts-chart')];
  }
};

// Make Closure Compiler treat this file as an ES module
export {};
