goog.declareModuleId('yext.partnerscan.noinline');

/*
 * Hide d-none tags
 */
export function replaceDNone() {
  const dNoneToConvert = $('.d-none');
  	dNoneToConvert.hide();
  	dNoneToConvert.toggleClass('d-none', false);
}
