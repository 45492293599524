goog.module('yext.ResponsiveFormValidation.validation');
goog.module.declareLegacyNamespace();
const yext = goog.require('yext');

const JQueryValidate = /** @type {JQueryValidateJS} */ (require('jquery-validation'));

/**
 * Sets up field validation for responsive resellers scan NAP input page
 */
const validation = function() {
  var isUS = $('form#scan-form').hasClass('isUS');
  var sublocalityRequired = ($('input[name=sublocality]')).hasClass('js-sublocality-required');
  var cityRequired = ($('input[name=city]')).hasClass('js-city-required');
  var stateRequired = ($('input[name=state]')).hasClass('js-state-required');
  var zipRequired = ($('input[name=zip]')).hasClass('js-zip-required');

  var requiredMessage = yext.msg('This field is required');

  // Checks that input doesn't exceed db limit
  $.validator.addMethod('limitLength', function(input, element) {
    var elName = element.name;
    if (['name', 'address'].indexOf(elName) > -1) {
      return input.length <= 255;
    } else {
      return input.length <= 80;
    }
  });

  // Checks if phone number is 10 digit US number
  $.validator.addMethod('phoneUS', function(phoneNumber, element) {
    phoneNumber = phoneNumber.replace(/\s+/g, '');
    return phoneNumber.length > 9
        && phoneNumber.match(/^(1-?)?(\([2-9]\d{2}\)|[2-9]\d{2})-?[2-9]\d{2}-?\d{4}$/);
  });

  // Checks if zip is 5 digit US zip
  $.validator.addMethod('zipUS', function(zip, element) {
    return zip.length === 5 && zip.match(/^\d{5}$/);
  });

  // Checks if website is valid URL
  $.validator.addMethod('website', function(website, element) {
    return website === ''
           || (website.match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,8}(:[0-9]{1,5})?(\/.*)?$/)
           && !website.includes('yelp.com'));
  });

  $('#scan-form').validate({
    errorPlacement: function($error, $element) {
      $error.appendTo($element.parent().find('.error-message-container'));
    },
    messages: {
      name: yext.msg('Specify a valid business name'),
      address: yext.msg('Specify a valid address name'),
      sublocality: yext.msg('Specify a valid sublocality name'),
      city: yext.msg('Specify a valid city name'),
      state: yext.msg('Specify a valid state name'),
      zip: isUS ? yext.msg('Specify a 5-digit zip code') : requiredMessage,
      phone: isUS ? yext.msg('Specify a 10-digit phone number') : requiredMessage,
      website: yext.msg('Specify a valid business website URL'),
    },
    onfocusout: false,
    onkeyup: false,
    rules: {
      name: {
        required: true,
        limitLength: true,
      },
      address: {
        required: true,
        limitLength: true,
      },
      sublocality: {
        required: sublocalityRequired,
        limitLength: true,
      },
      city: {
        required: cityRequired,
        limitLength: true,
      },
      phone: {
        required: true,
        phoneUS: isUS,
      },
      state: {
        required: stateRequired,
        limitLength: true,
      },
      zip: {
        required: zipRequired,
        zipUS: isUS,
      },
      website: {
        required: false,
        website: true,
      },
    },
  });

  $('#scan-form').submit(function(event) {
    function removeErrorClassIfValid($input) {
      // For certain countries, certain input fields might not exist
      if (!($input).length) {
        return;
      }

      var $inputLine = $input.parent().find('.scan-form-field-line');
      if ($input.valid() == true) {
        $inputLine.removeClass('error');
      } else {
        $inputLine.addClass('error');
      }
    }

    removeErrorClassIfValid($('input[name=name]'));
    removeErrorClassIfValid($('input[name=address]'));
    removeErrorClassIfValid($('input[name=sublocality]'));
    removeErrorClassIfValid($('input[name=city]'));
    removeErrorClassIfValid($('input[name=state]'));
    removeErrorClassIfValid($('input[name=zip]'));
    removeErrorClassIfValid($('input[name=phone]'));
    removeErrorClassIfValid($('input[name=website]'));
  });
};

goog.exportSymbol('yext.ResponsiveFormValidation.validation', validation);

exports = validation;
