goog.module('yext.partnerscan.init');

const {default: jsInit} = goog.require('yext.jsinit');
const noInline = goog.require('yext.partnerscan.noinline');

const initFunction = function() {
  var isResponsive = document.getElementById('js-init').getAttribute('data-isresponsive');
  if (isResponsive) {
    yext.ResponsiveFormValidation.validation();
    diagnosticForm = new yext.partnerscan.diagnosticform.DiagnosticForm();
  }

  $('a[href=top]').click(function() {
    $('html,body').animate({scrollTop: 0}, 500);
    return false;
  });
  $('.submit-button').click(function() {
    $(this).parents('form').submit();
  });
};

const init = jsInit({
  'partnerscan': initFunction,
  'replaceDNone': noInline.replaceDNone,
});

document.addEventListener('DOMContentLoaded', init);
