/**
 * @fileoverview An error bar used in the scan summaries
 *
 * @author Oscar Li (oscar@yext.com)
 */
goog.module('yext.locationscan_common.ErrorBar');
goog.module.declareLegacyNamespace();

/**
 * @constructor
 * @param {!jQuery} ctx
 */
const ErrorBar = function($ctx) {
  /**
   * @type {!jQuery}
   * @private
   */
  this.$ctx_ = $ctx;

  /**
   * @type {!jQuery}
   * @private
   */
  this.$innerBar_ = $ctx.find('.js-inner-error-bar');

  /**
   * @type {!jQuery}
   * @private
   */
  this.$percentage_ = $ctx.find('.js-error-percentage');

  /**
   * @type {number}
   * @private
   */
  this.percentage_ = 0;
};

ErrorBar.prototype.setPercentage = function(percentage) {
  if (this.percentage_ === percentage) {
    return;
  }
  this.percentage_ = percentage;
  this.$innerBar_.css('width', this.percentage_ + '%');
  this.$percentage_.html(Math.floor(this.percentage_));

  this.$ctx_.toggleClass(
    'error',
    this.percentage_ >= yext.locationscan_common.scanresults.ERROR_RATE_BOUNDARIES.ERROR);
  this.$ctx_.toggleClass(
    'warning',
    this.percentage_ < yext.locationscan_common.scanresults.ERROR_RATE_BOUNDARIES.ERROR
    && this.percentage_ >= yext.locationscan_common.scanresults.ERROR_RATE_BOUNDARIES.WARNING);
  this.$ctx_.toggleClass(
    'success',
    this.percentage_ < yext.locationscan_common.scanresults.ERROR_RATE_BOUNDARIES.WARNING);
};

exports = ErrorBar;
