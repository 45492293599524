/**
 * @fileoverview Templates - provides utils for rendering soy templates in js
 *
 * @author Samantha Landen (slanden@yext.com)
 */
goog.module('yext.templates');
goog.module.declareLegacyNamespace();

/**
 * @param {function()} template The template to be rendered
 * @param {!Object=} opt_params The params for the template (if null, no params will be provided)
 *
 * @return {string} The content of the rendered template
 */
function render(template, opt_params) {
  opt_params = opt_params || {};

  return template(opt_params).content;
}

exports = {
  render,
};
