/**
 * @fileoverview This class hold the information needed to show details about possible
 * duplicate locations from a scan.
 *
 * @author Jeffrey Sham (jsham@yext.com)
 */
goog.module('yext.locationscan_common.ScanLocationDetails');
goog.module.declareLegacyNamespace();

/**
 * @constructor
 */
const ScanLocationDetails = function() {
  this.template = $('.js-location-details-template').html();
};

exports = ScanLocationDetails;
