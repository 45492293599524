goog.declareModuleId('yext.jsinit');

/**
 * Checks for 'js-init' elements on the page and initialize their corresponding
 * modules
 * @param {!Object<string, function(!Object)>} entryPoints mapping from
 *     identifier to initializer
 * @param {string=} [selector=.js-init] selector to use to find elements
 * @return {function()} an initializer function for the page
 */
export default function jsInit(entryPoints, selector) {
  return () => {
    /** @type {!Array<!HTMLElement>} */
    const modules = Array.from(document.querySelectorAll(selector || '.js-init'));
    modules.forEach(e => {
      const id = e.dataset['jsinit'];
      const json = e.dataset['params'] || e.textContent;
      /** @type {!Object} */
      let params = {};
      if (json.trim()) {
        const parsedJson = JSON.parse(json);
        if (typeof parsedJson !== 'object') {
          throw new Error('jsinit params must be an object');
        }
        params = /** @type {!Object} */ (parsedJson);
      }
      const initializer = entryPoints[id];
      if (initializer) {
        initializer(params);
      }
    });
  };
}

