goog.provide('yext.partnerscan.diagnosticform');

/** @suppress {extraRequire} */
goog.require('yext.plugins.selectbox');
require('jquery-ui');

/**
 * @constructor
 */
yext.partnerscan.diagnosticform.DiagnosticForm = function() {
  this.bindInputSelect();
  this.bindCountrySelect();
};

yext.partnerscan.diagnosticform.DiagnosticForm.prototype.bindInputSelect = function() {
  var $lines = $('.js-field-line');

  // Darkens the input line when the input is selected
  $('.js-input-field').click(function() {
    $lines.filter('.selected').removeClass('selected');
    $(this).siblings('.js-field-line').addClass('selected');
  });
};

yext.partnerscan.diagnosticform.DiagnosticForm.prototype.bindCountrySelect = function() {
  const element = document.querySelector('.js-country-select');
  if (element != null) {
    // NOTE(jsham): We need to add jquery-ui script to get the selectbox working properly
    // However, since the diagnostic responsive form is used by Yext and partners, we do
    // not know how the partners will render their page.
    $('body').append('<script src="//ajax.googleapis.com/ajax/libs/jqueryui/1.12.1/jquery-ui.min.js"></script>');

    $(element).selectbox();
    $(element).change(function() {
      window.location.replace(location.pathname + '?psc=' + this.value);
    });
  }
};

goog.exportSymbol('yext.partnerscan.diagnosticform.DiagnosticForm', yext.partnerscan.diagnosticform.DiagnosticForm);
