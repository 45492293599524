/**
 * @deprecated yext.localization is deprecated in favor of the built-in Intl object:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl
 */
goog.provide('yext.localization');

/** @type {Globalize} */
yext.localization.global;
/** @type {?} */
yext.localization.cldrData;

// reversedLocales is a list of country codes where the time comes after the date
yext.localization.reversedLocales = {};

yext.localization.DEFAULT_DECIMAL_SYMBOL = '.';
yext.localization.DEFAULT_THOUSANDS_SEP = ',';

yext.localization.init = function() {
  var main = Cldr._resolved.main;
  // currentLanguageFallbacks appears to be unset in some corner cases.
  // So this uses an additional fallback to it.
  var fallbacks;
  if (window.currentLanguageFallbacks) {
    fallbacks = window.currentLanguageFallbacks;
  } else {
    console.error(
      'window.currentLanguageFallbacks is unset. Forcing a fallback.');
    fallbacks = ['en-US', 'en'];
  }
  var primaryTag;
  for (var i in fallbacks) {
    var tag = fallbacks[i];
    if (tag in main) {
      primaryTag = tag;
      yext.localization.cldrData = main[tag];
      break;
    }
  }
  // new window.Globalize will look for en if given en-US, de if given, de-DE, etc.
  // so it's good to be (perhaps pointlessly) exhaustive here
  for (var i in fallbacks) {
    var tag = fallbacks[i];
    main[tag] = yext.localization.cldrData;
  }
  main[window.currentLocaleCode] = yext.localization.cldrData; // Probably not necessary
  yext.localization.global = new window.Globalize(primaryTag);
};
goog.exportSymbol('yext.localization.init', yext.localization.init);

Date.prototype.format = function(pattern) {
  if (pattern === 'hms') {
    pattern = {time: 'medium'};
  } else if (pattern === 'hm') {
    pattern = {time: 'short'};
  }

  return yext.localization.global.formatDate(this, pattern);
};

yext.localization.formatNumber = function(num, options) {
  return yext.localization.global.formatNumber(num, options);
};

yext.localization.formatDateWithTime = function(datePattern, timePattern, date) {
  if (window.currentLanguage in yext.localization.reversedLocales) {
    return date.format(timePattern) + ' ' + date.format(datePattern);
  }
  return date.format(datePattern) + ' ' + date.format(timePattern);
};

yext.localization.formatDateTimeRange = function(datePattern, timePattern, start, end) {
  // TRANSLATORS: Date time range
  // EXAMPLE: Oct 7, 10:00 AM - 12:30 PM
  return yext.msgc('datetime', '{0}, {1} - {2}',
    start.format(datePattern),
    start.format(timePattern),
    end.format(timePattern));
};

yext.localization.formatTimeRange = function(timePattern, start, end) {
  // TRANSLATORS: Time range
  // EXAMPLE: 10:00 AM - 12:30 PM
  return yext.msgc('datetime', '{0} - {1}',
    start.format(timePattern),
    end.format(timePattern));
};

yext.localization.formatMultiDateTimeRange = function(datePattern, timePattern, start, end) {
  // TRANSLATORS: Time range
  // EXAMPLE: Jan 3, 10:00 AM - Jan 4, 12:00 PM
  return yext.msgc('datetime', '{0}, {1} - {2}, {3}',
    start.format(datePattern), start.format(timePattern),
    end.format(datePattern), end.format(timePattern));
};

yext.localization.getTimeFormat = function(formatName) {
  return yext.localization.cldrData.dates.calendars.gregorian.timeFormats[formatName];
};

yext.localization.convertToLocalTime = function(jQuery) {
  jQuery.each(function() {
    var serverDate = parseInt($(this).html(), 10);
    if (!serverDate) {
      return;
    }
    var localDate = new Date(serverDate);
    $(this).html(yext.localization.formatDateWithTime('yMMMd', 'hm', localDate));
  });
};
goog.exportSymbol('yext.localization.convertToLocalTime', yext.localization.convertToLocalTime);

yext.localization.getDay = function(englishDay, format) {
  switch (englishDay) {
    case 'Monday':
      return yext.localization.cldrData.dates.calendars.gregorian.days.format[format].mon;
    case 'Tuesday':
      return yext.localization.cldrData.dates.calendars.gregorian.days.format[format].tue;
    case 'Wednesday':
      return yext.localization.cldrData.dates.calendars.gregorian.days.format[format].wed;
    case 'Thursday':
      return yext.localization.cldrData.dates.calendars.gregorian.days.format[format].thu;
    case 'Friday':
      return yext.localization.cldrData.dates.calendars.gregorian.days.format[format].fri;
    case 'Saturday':
      return yext.localization.cldrData.dates.calendars.gregorian.days.format[format].sat;
    case 'Sunday':
      return yext.localization.cldrData.dates.calendars.gregorian.days.format[format].sun;
  }
  return englishDay;
};

/**
 * Returns the localized full month name.
 * @param {number} monthNumber indexed starting at 1 (i.e. January)
 * @return {string}
 */
yext.localization.getMonth = function(monthNumber) {
  return yext.localization.cldrData.dates.calendars
    .gregorian.months['stand-alone'].wide[monthNumber];
};

/**
 * Returns the localized abbrevmonth name.
 * @param {number} monthNumber indexed starting at 1 (i.e. January)
 * @return {string}
 */
yext.localization.getMonthAbbreviated = function(monthNumber) {
  return yext.localization.cldrData.dates.calendars
    .gregorian.months['stand-alone'].abbreviated[monthNumber];
};

/**
 * Returns the localized day of week
 * @param {string} englishDay you wish to be localized (must be
 * capitalized & in full i.e. Monday)
 * @return {string}
 */
yext.localization.getDayOfWeek = function(englishDay) {
  return yext.localization.getDay(englishDay, 'wide');
};

/**
 * Returns the localized, abbreviated day of week
 * @param {string} englishDay you wish to be localized (must be
 * capitalized & in full i.e. Monday)
 * @return {string}
 */
yext.localization.getDayOfWeekAbbreviated = function(englishDay) {
  return yext.localization.getDay(englishDay, 'abbreviated');
};

/**
 * Check if the current locale is US
 *
 * @return {boolean} True if locale is US (en or en_US)
 */
yext.localization.isUSLocale = function() {
  return window.currentLocaleCode === 'en' || window.currentLocaleCode === 'en_US';
};

/**
 * Returns the decimal symbol for the current window locale
 * @return {string} decimal symbol
 */
yext.localization.getDecimalSymbol = function() {
  var numbersObj = yext.localization.cldrData['numbers'];
  if (!numbersObj) {
    return yext.localization.DEFAULT_DECIMAL_SYMBOL;
  }
  return numbersObj['symbols-numberSystem-' + numbersObj.defaultNumberingSystem].decimal;
};

/**
 * Returns the thousands separator symbol for the current window locale
 * @return {string} thousands separator symbol
 */
yext.localization.getThousandsSeparator = function() {
  var numbersObj = yext.localization.cldrData['numbers'];
  if (!numbersObj) {
    return yext.localization.DEFAULT_THOUSANDS_SEP;
  }
  return numbersObj['symbols-numberSystem-' + numbersObj.defaultNumberingSystem].group;
};
