goog.provide('yext.locationscan_common.scanresults');
goog.provide('yext.locationscan_common.PublisherDetailsView');

goog.require('goog.dom');
goog.require('goog.ui.Textarea');
goog.require('goog.format.EmailAddress');
goog.require('yext.locationscan_common.ErrorBar');
goog.require('yext.locationscan_common.ScanLocationDetails');
goog.require('yext.locationscan_common.publisherscan');
goog.require('yext.locationscan_common.pagesscan');
goog.require('yext.locationscan_common.scanResultsTemplates');
goog.require('yext');
goog.require('yext.templates');

yext.locationscan_common.scanresults.init = function(options) {
  options = options || {};
  yext.locationscan_common.scanresults.TEMPLATES = {
    SHARE_SCAN: $('.share-scan-template').html(), // Partner Scan only
  };
  yext.locationscan_common.scanresults.SCAN_ICONS = {
    INCORRECT_FIELD: $('.js-scan-icon-incorrect-field')[0],
    CORRECT_FIELD: $('.js-scan-icon-correct-field')[0],
  };
  yext.locationscan_common.scanresults.DIALOG_BOX = new yext.locationscan_common.scanresults.DialogView(false);
  // Partner Scan only
  yext.locationscan_common.scanresults.DIALOG_WHOLE_SCREEN = new yext.locationscan_common.scanresults.DialogView(true);

  var view = new yext.locationscan_common.scanresults.ScanResults(options);

  return new yext.locationscan_common.scanresults.AppInterface(view, options['shareLinkId']);
};
goog.exportSymbol('yext.locationscan_common.scanresults.init', yext.locationscan_common.scanresults.init);

/**
 * Lower bound percentages for changing colors
 */
yext.locationscan_common.scanresults.ERROR_RATE_BOUNDARIES = {
  WARNING: 10,
  ERROR: 50,
};

yext.locationscan_common.scanresults.COUNT_PERCENT_CLASS_NAME = 'count-percent';
yext.locationscan_common.scanresults.RATING_PERCENT_CLASS_NAME = 'rating-percent';

/**
  * @constructor
  */
yext.locationscan_common.scanresults.AppInterface = function(view, shareLinkId) {
  this.view = view;
  this.shareLinkId = shareLinkId;
};

yext.locationscan_common.scanresults.AppInterface.prototype.showScanSummary = function() {
  this.toggleScanPanels(false);
};

yext.locationscan_common.scanresults.AppInterface.prototype.showScanDetails = function() {
  this.toggleScanPanels(true);
};

yext.locationscan_common.scanresults.AppInterface.prototype.getShareLink = function() {
  return '/locationscan/s/' + this.shareLinkId;
};

yext.locationscan_common.scanresults.AppInterface.prototype.getLocation = function() {
  return JSON.stringify(this.view.location);
};

yext.locationscan_common.scanresults.AppInterface.prototype.toggleScanPanels = function(showPartners) {
  this.view.$partnerList.toggle(showPartners);
  this.view.$scanSummary.toggle(!showPartners);
  // Reset the scroll position
  window.scrollTo(0, 0);
};

goog.exportProperty(
  yext.locationscan_common.scanresults.AppInterface.prototype,
  'showScanDetails',
  yext.locationscan_common.scanresults.AppInterface.prototype.showScanDetails);
goog.exportProperty(
  yext.locationscan_common.scanresults.AppInterface.prototype,
  'showScanSummary',
  yext.locationscan_common.scanresults.AppInterface.prototype.showScanSummary);
goog.exportProperty(
  yext.locationscan_common.scanresults.AppInterface.prototype,
  'getShareLink',
  yext.locationscan_common.scanresults.AppInterface.prototype.getShareLink);
goog.exportProperty(
  yext.locationscan_common.scanresults.AppInterface.prototype,
  'getLocation',
  yext.locationscan_common.scanresults.AppInterface.prototype.getLocation);

/**
 * @constructor
 */
yext.locationscan_common.scanresults.DialogView = function(isWholeScreen) {
  this.isWholeScreen = isWholeScreen;
  if (this.isWholeScreen) {
    this.$modal = $('.js-whole-screen-modal');
  } else {
    this.$modal = $('.js-box-modal');
  }
  this.$dialog = this.$modal.find('.js-scan-details-dialog');
  this.bindings();
};

yext.locationscan_common.scanresults.DialogView.prototype.bindings = function() {
  var self = this;
  if (!this.isWholeScreen) {
    this.$modal.on('click', function(e) {
      if (e.target == self.$modal[0]) {
        self.hide();
      }
    });
  }
  this.$modal.find('.js-close-dialog').on('click', function() {
    self.hide();
  });
  $(window).resize(function() {
    if (self.$dialog.is(':visible')) {
      self.position();
    }
    var $how = $('.js-error-rate-how');
    var $tooltip = $('.js-error-rate-how-tooltip');
    $tooltip.position({
      'my': 'right center',
      'at': 'left-10 center',
      'of': $how,
    });
  });
};

yext.locationscan_common.scanresults.DialogView.prototype.show = function() {
  this.scrollTop = $('body').scrollTop();
  this.$modal.show();
  $('body').addClass('no-scroll');
  this.position();
};

yext.locationscan_common.scanresults.DialogView.prototype.position = function() {
  if (!this.isWholeScreen) {
    this.$dialog.css('margin-top', 0);

    var dialogHeight = this.$dialog.outerHeight();
    var windowHeight = $(window).height();
    var margin = Math.max((windowHeight - dialogHeight) / 2, 0);

    this.$dialog.css('margin-top', margin + 'px');
  }
};

yext.locationscan_common.scanresults.DialogView.prototype.hide = function() {
  $('body').removeClass('no-scroll');
  // Restore scroll position for unhappy browsers
  $('body').scrollTop(this.scrollTop);
  this.$modal.hide();
};

yext.locationscan_common.scanresults.DialogView.prototype.validate = function() {
  var error = '';
  var toValue = $('#scan-to').val();
  var fromValue = $('#scan-from').val();
  var hasContentValue = $('#scan-content').length > 0;
  var contentValue = $('#scan-content').val();
  var hasLinkTextValue = $('#scan-scanLinkText').length > 0;
  var linkTextValue = $('#scan-scanLinkText').val();
  var hasSignatureValue = $('#scan-signature').length > 0;
  var signatureValue = $('#scan-signature').val();

  if (!goog.format.EmailAddress.isValidAddress(toValue)) {
    error = 'To email address is invalid.';
  } else if (!goog.format.EmailAddress.isValidAddress(fromValue)) {
    error = 'From email address is invalid.';
  } else if (hasContentValue && contentValue.length > 4000) {
    error = 'Content length cannot exceed 4000 characters.';
  } else if (hasLinkTextValue && (linkTextValue.length < 10 || linkTextValue.length > 100)) {
    error = 'Link text must be between 10 and 100 characters.';
  } else if (hasSignatureValue && signatureValue.length > 100) {
    error = 'Signature must be less than 100 characters.';
  }

  return error;
};

yext.locationscan_common.scanresults.DialogView.prototype.setContent = function(content) {
  this.$dialog.find('.js-content').html(content);
  var $lines = this.$dialog.find('.js-field-line');

  var self = this;
  this.$dialog.find('#share-submit').click(function() {
    var error = self.validate();
    var $errorBox = self.$dialog.find('#error');
    if (error == '') {
      self.hide();

      $(this).closest('form').submit()[0].reset();

      $lines.filter('.selected').removeClass('selected');
      $errorBox.hide();
    } else {
      $errorBox.text(error);
      $errorBox.show();
    }
  });

  // Darkens the input line when the input is selected
  this.$dialog.find('.js-input-field').click(function() {
    $lines.filter('.selected').removeClass('selected');
    $(this).siblings('.js-field-line').addClass('selected');
  });
  return this;
};

/**
 * @constructor
 * @param {boolean} embedded
 */
yext.locationscan_common.scanresults.ScanResults = function(options) {
  this.$locationDetails = $('.js-location-details');
  this.$partnerList = $('.js-partner-list');
  this.$scanSummary = $('.js-scan-summary');
  this.$inaccuracyLevel = $('.js-inaccuracy-level');
  this.$reviewsLevel = $('.js-reviews-level');
  this.$scanResultDetails = $('.js-scan-result-details');
  this.$scanProgress = $('.js-scan-progress');
  this.$reviewTitleSection = $('.js-review-title-section');
  this.$reviewsStatsLabel = $('.js-reviews-stats-label');

  this.$schemaErrorPercentage = $('.js-pages-rate-percentage');
  this.$schemaErrorLevel = $('.js-missing-schema-level');
  this.$schemaErrorLabel = $('.js-pages-stats-label');

  this.embedded = !!options['embedded'];
  this.platform = options['platform'];
  this.sharePage = !!options['sharePage'];
  this.appVersion = options['appVersion'];
  this.appsPartnerId = options['appsPartnerId'];
  this.hasSpring18Release = !!options['hasSpring18Release'];
  this.website = options['website'];
  this.location = this.$locationDetails.data('location');
  this.locationId = this.$locationDetails.data('locationId');
  this.suppressNap = this.$locationDetails.data('suppressNap');
  this.showReviews = this.$locationDetails.data('showReviews');
  this.isPartnerScan = this.$locationDetails.data('isPartnerScan');
  this.hideFeaturedMessage = this.$locationDetails.data('hideFeaturedMessage');
  this.partners = this.getPartners();
  this.reports = {};
  this.errorCount = 0;
  this.scanErrorCount = 0; // Issues scanning the pub
  this.nameErrorCount = 0;
  this.addressErrorCount = 0;
  this.phoneNumberErrorCount = 0;
  this.email = options['email'];
  this.totalReviewCount = 0;
  this.averageReviewScore = 0;
  this.averageRatingPercentile = 0;
  this.averageCountPercentile = 0;
  this.hasCount = false;
  this.hasScore = false;
  this.productName = options['productName'];
  this.calculateBaseYextCategoryId = options['calculateBaseYextCategoryId'];
  this.pagesScanId = options['pagesScanId'];
  this.totalScans = 0;
  this.finishedScans = 0;

  // Reset the scroll position
  window.scrollTo(0, 0);
  yext.locationscan_common.scanresults.bindShowLocationDetails(this.$locationDetails);
  this.bindInfoIcon();
  this.bindViewLocationDetailsModal();
  this.initErrorBars();

  if (this.suppressNap && !this.showReviews && !this.pagesScanId && this.hasSpring18Release) {
    this.switchToLocationDetails();
  } else {
    this.startScan();
  }
};

yext.locationscan_common.scanresults.ScanResults.prototype.bindAutosizingTextAreas = function() {
  if ($('#scan-content').length > 0 ) {
    var textArea1 = new goog.ui.Textarea();
    textArea1.decorate(goog.dom.getElement('scan-content'));
  }
  if ($('#scan-signature').length > 0 ) {
    var textArea2 = new goog.ui.Textarea();
    textArea2.decorate(goog.dom.getElement('scan-signature'));
  }
};

yext.locationscan_common.scanresults.ScanResults.prototype.bindInfoIcon = function() {
  if (this.sharePage) {
    if ($(window).width() > 1000) {
      this.bindInfoTooltip();
    } else {
      this.bindInfoModal();
    }
  } else {
    this.bindInfoModal();
  }
};

yext.locationscan_common.scanresults.ScanResults.prototype.bindInfoModal = function() {
  var $modal = $('.js-error-rate-how-modal');
  var $card = $modal.find('.js-error-rate-how-card');

  var hiding = false;
  var closeTimeout = undefined;

  $('.js-error-rate-how').on('click', function() {
    if (!hiding) {
      hiding = false;
      $modal.show();
      setTimeout(function() {
        $card.css('top', '35%');
      }, 30);
      $('body').addClass('no-scroll');
    }
  });

  var hideCard = function() {
    hiding = true;
    setTimeout(function() {
      $card.css('top', '100%');
    }, 30);
    closeTimeout = setTimeout(function() {
      hideModal();
    }, 340);
  };

  var hideModal = function() {
    clearTimeout(closeTimeout);
    $modal.hide();
    $('body').removeClass('no-scroll');
    $card.css('top', '100%');
    hiding = false;
  };

  $modal.find('.js-close').on('click', hideCard);

  $modal.on('click', function(e) {
    if (e.target == $modal[0]) {
      hideCard();
    }
  });

  $card.on('webkitTransitionEnd transitionend', function() {
    if (hiding) {
      hideModal();
    }
  });
};

yext.locationscan_common.scanresults.ScanResults.prototype.bindInfoTooltip = function() {
  var $how = $('.js-error-rate-how');
  var $tooltip = $('.js-error-rate-how-tooltip');

  $how.on('click', function() {
    if ($tooltip.is(':visible')) {
      $tooltip.hide();
    } else {
      $tooltip.show()
        .position({
          'my': 'right center',
          'at': 'left-10 center',
          'of': $how,
        });
    }
  });
};

yext.locationscan_common.scanresults.ScanResults.prototype.bindViewLocationDetailsModal = function() {
  var $locationDetails = new yext.locationscan_common.ScanLocationDetails();
  var $locationDetailsModal = $('.js-view-location-details-modal');
  $locationDetailsModal.on('click', function() {
    yext.locationscan_common.scanresults.DIALOG_BOX.setContent($locationDetails.template).show();
  });
};

yext.locationscan_common.scanresults.ScanResults.prototype.getPartners = function() {
  var partners = [];
  this.$partnerList.find('.js-partner').each(function() {
    var partner = $(this).data();
    partners.push(partner);
  });
  return partners;
};

yext.locationscan_common.scanresults.ScanResults.prototype.initErrorBars = function() {
  this.nameErrors = new yext.locationscan_common.ErrorBar($('.js-error-category-name'));
  this.addressErrors = new yext.locationscan_common.ErrorBar($('.js-error-category-address'));
  this.phoneErrors = new yext.locationscan_common.ErrorBar($('.js-error-category-phone'));
  this.ratingPercent = new yext.locationscan_common.ErrorBar($('.js-review-percentile'));
  this.countPercent = new yext.locationscan_common.ErrorBar($('.js-count-percentile'));
  this.schemaErrors = new yext.locationscan_common.ErrorBar($('.js-error-schema'));
};

yext.locationscan_common.scanresults.ScanResults.prototype.getReportView = function(partnerId, brandId) {
  if (this.reports[partnerId]) {
    return this.reports[partnerId][brandId];
  }
};

yext.locationscan_common.scanresults.ScanResults.prototype.getBrandName = function(partnerId, brandId) {
  for (var i = 0; i < this.partners.length; i++) {
    if (this.partners[i]['id'] == partnerId && this.partners[i]['brandId'] == brandId) {
      return this.partners[i]['name'];
    }
  }
};

/**
 * @param {ReportView}
 */
yext.locationscan_common.scanresults.ScanResults.prototype.addReportView = function(view) {
  // Map view by partner and brand id
  if (!this.reports[view.model.partnerId]) {
    this.reports[view.model.partnerId] = {};
  }
  this.reports[view.model.partnerId][view.model.brandId] = view;
};

yext.locationscan_common.scanresults.ScanResults.prototype.startScan = function() {
  var self = this;

  var candidates = [];
  for (var i = 0; i < this.partners.length; i++) {
    var candidate = new yext.locationscan_common.publisherscan.ScanCandidate(
      this.partners[i]['id'],
      this.partners[i]['brandId']);
    candidates.push(candidate);
  }
  this.totalScans = candidates.length;
  if (self.pagesScanId) {
    this.totalScans++;
  }

  var scan = new yext.locationscan_common.publisherscan.LongPollScan(
    candidates,
    this.location,
    this.locationId,
    this.appVersion,
    this.appsPartnerId,
    this.calculateBaseYextCategoryId);

  scan.onScansCompleteCallback(function(reports, otherResponseFields) {
    self.hasBaseYextCategoryId = scan.hasBaseYextCategoryId;
    reports.forEach(function(data) {
      // Hack for google reviews and Tupalo, where review_count is not returned from
      // SmsRealtimeServer when there are no reviews. In other words, review_count = 0 should be
      // returned.
      if (data['partnerId'] === 713 || data['partnerId'] === 502) {
        if (!data['averageReviewScore']) {
          data['reviewCount'] = 0;
          data['hasReviewCount'] = true;
        }
      }

      self.hasCount = self.hasCount || !!data['reviewCount'] || data['reviewCount'] === 0;
      self.hasScore = self.hasScore || !!data['averageReviewScore'];
      data['hideFeaturedMessage'] = self.hideFeaturedMessage;
      var websiteSupported = jQuery.inArray(
        data['partnerId'],
        otherResponseFields['websitePartners']) > -1;
      var hoursSupported = jQuery.inArray(
        data['partnerId'],
        otherResponseFields['hoursPartners']) > -1;
      var categorySupported = jQuery.inArray(
        data['partnerId'],
        otherResponseFields['categoryPartners']) > -1;
      var brandName = self.getBrandName(data['partnerId'], data['brandId']);
      var report = new yext.locationscan_common.scanresults.Report(
        data,
        websiteSupported,
        hoursSupported,
        categorySupported);
      var view = new yext.locationscan_common.scanresults.ReportView(
        report,
        brandName,
        self.appVersion,
        self.productName,
        self.showReviews,
        self.locationId,
        self.suppressNap,
        self.isPartnerScan);

      if (report.reviewCount) {
        self.totalReviewCount += report.reviewCount;
      }

      if (self.sharePage) {
        view.bindDetailsPopup();
      } else {
        view.setDetailsUrl(scan.locationString, self.embedded, self.locationId, self.platform);
      }
      self.addReportView(view);
      self.updateStats(view);
      self.renderProgress();

      // Updates and renders review data
      if (otherResponseFields['reviewScorePercentile'] !== undefined
          && otherResponseFields['reviewCountPercentile'] !== undefined) {
        self.averageRatingPercentile = otherResponseFields['reviewScorePercentile'];
        self.averageCountPercentile = otherResponseFields['reviewCountPercentile'];
        self.renderReviewStats();
      }
    });
  });

  scan.onScanComplete(function() {
    if (self.totalReviewCount <= 0 && !self.hasScore) {
      self.renderNoReviews();
    }
  });

  scan.run();

  if (self.pagesScanId) {
    var successCallback = function(response) {
      self.pagesScanComplete = true;
      self.schemaErrors.setPercentage(response['schemaErrorPercentage']);
      self.$schemaErrorPercentage.html(response['schemaErrorPercentage'] + '%');
      self.$schemaErrorLabel.html(
        // TRANSLATORS: This sentence is preceded by a percentage.
        // EXAMPLE: 20% missing schema tags on your website.
        yext.msg('missing business schema tags on your website'));
      if (response['schemaErrorPercentage'] >= yext.locationscan_common.scanresults.ERROR_RATE_BOUNDARIES.ERROR) {
        self.$schemaErrorLevel.addClass('error');
      } else if (response['schemaErrorPercentage'] >= yext.locationscan_common.scanresults.ERROR_RATE_BOUNDARIES.WARNING) {
        self.$schemaErrorLevel.addClass('warning');
      } else {
        self.$schemaErrorLevel.addClass('success');
      }
      self.updateTemplate(response);
      self.bindDetailsModal(response);
      self.renderProgress();
    };

    var errorCallback = function() {
      self.$schemaErrorPercentage.html('X');
      self.schemaErrors.setPercentage(100);
      self.$schemaErrorLabel.html(yext.msg('Error scanning your website'));
      self.$schemaErrorLevel.addClass('error');
      var html = yext.msg('There was a temporary problem scanning your website');
      $('.js-pages-scan-results').html(html);
      self.renderProgress();
    };
    var pagesScan = new yext.locationscan_common.pagesscan.PagesScan(
      self.pagesScanId,
      self.appsPartnerId,
      self.location,
      successCallback,
      errorCallback);
    pagesScan.run();
  }
};

yext.locationscan_common.scanresults.ScanResults.prototype.updateTemplate = function(response) {
  if (response['hasFall19Release']) {
    var html = yext.templates.render(yext.locationscan_common.scanResultsTemplates.schemaResultsTemplate,
      {
        name: response['name']
                ? response['name']
                : yext.msg('Missing Name Schema'),
        address: response['address']
                   ? response['address']
                   : yext.msg('Missing Address Schema'),
        phone: response['phone']
                 ? response['phone']
                 : yext.msg('Missing Phone Schema'),
        reviews: response['hasReviewsSchema']
                   ? yext.msg('Reviews Schema Found')
                   : yext.msg('Missing Reviews Schema'),
        hasFall19Release: response['hasFall19Release'],
      });

    $('.js-pages-scan-results').html(html);

    this.updateField(response['matchName'], $('.js-schema-name'));
    this.updateField(response['matchAddress'], $('.js-schema-address'));
    this.updateField(response['matchPhone'], $('.js-schema-phone'));
  } else {
    var html = yext.templates.render(yext.locationscan_common.scanResultsTemplates.schemaResultsTemplate,
      {
        name: response['name'] ? yext.msg('Name Schema Found') : yext.msg('Missing Name Schema'),
        address: response['address'] ? yext.msg('Address Schema Found') : yext.msg('Missing Address Schema'),
        phone: response['phone'] ? yext.msg('Phone Schema Found') : yext.msg('Missing Phone Schema'),
        reviews: response['hasReviewsSchema'] ? yext.msg('Reviews Schema Found') : yext.msg('Missing Reviews Schema'),
        hasFall19Release: response['hasFall19Release'],
      });

    $('.js-pages-scan-results').html(html);

    this.updateField(response['name'], $('.js-schema-name'));
    this.updateField(response['address'], $('.js-schema-address'));
    this.updateField(response['phone'], $('.js-schema-phone'));
    this.updateField(response['hasReviewsSchema'], $('.js-schema-reviews'));
  }
};

yext.locationscan_common.scanresults.ScanResults.prototype.updateField = function(isFound, $field) {
  yext.locationscan_common.scanresults.ReportView.prototype.renderFieldStatusIcon(isFound, $field);
  if (!isFound) {
    $field.addClass('listing-error-text');
  }
};

yext.locationscan_common.scanresults.ScanResults.prototype.bindDetailsModal = function(response) {
  var $details = this.modalDetails(response);
  $('.js-website-modal').on('click', function() {
    yext.locationscan_common.scanresults.DIALOG_BOX.setContent($details).show();
  });
};

yext.locationscan_common.scanresults.ScanResults.prototype.appendSchemaBadge = function(foundValue, $field) {
  if ($field.find('.badges').length == 0) {
    $field.append('<span class="badges"></span>');
  }
  if (!foundValue) {
    var badge = yext.locationscan_common.scanresults.ReportView.prototype.templateBadge(
      'error',
      yext.msg('Missing Schema'));
    $field.find('.badges').addClass('no-match').append(badge);
  } else {
    var badge = yext.locationscan_common.scanresults.ReportView.prototype.templateBadge(
      'success',
      yext.msg('Found Schema'));
    $field.find('.badges').append(badge);
  }
};

yext.locationscan_common.scanresults.ScanResults.prototype.appendDataBadge = function(matchValue, $field) {
  if ($field.find('.badges').length == 0) {
    $field.append('<span class="badges"></span>');
  }
  if (!matchValue) {
    var badge = yext.locationscan_common.scanresults.ReportView.prototype.templateBadge(
      'error',
      yext.msg('Incorrect Data'));
    $field.find('.badges').append(badge);
  }
};

yext.locationscan_common.scanresults.ScanResults.prototype.modalDetails = function(response) {
  var html = yext.templates.render(
    yext.locationscan_common.scanResultsTemplates.scanDetailsSchemaTemplate,
    {
      foundSchemaTags: response['numFoundSchemaTags'] ? response['numFoundSchemaTags'] : 0,
      missingSchemaTags: response['numMissingSchemaTags'] ? response['numMissingSchemaTags'] : 0,
      schemaCount: yext.msgn('1 Schema Tag Found', '{0} Schema Tags Found', response['numFoundSchemaTags']),
      locationName: response['name'] ? response['name'] : '',
      locationPhone: response['phone'] ? response['phone'] : '',
      locationAddress: response['address'] ? response['address'] : '',
      aggregateRating: response['aggregateRating'] ? response['aggregateRating'] : '',
      website: this.website,
      hasSummer19Release: response['hasSummer19Release'] ? response['hasSummer19Release'] : false,
      numIncorrectFields: response['numIncorrectFields'],
    });

  var $details = $(html);
  this.appendSchemaBadge(response['name'], $details.find('.js-details-name'));
  this.appendSchemaBadge(response['address'], $details.find('.js-details-address'));
  this.appendSchemaBadge(response['phone'], $details.find('.js-details-phone'));
  this.appendSchemaBadge(response['businessWebsiteUrl'], $details.find('.js-details-website'));
  this.appendSchemaBadge(response['hasHoursSchema'], $details.find('.js-details-hours'));
  this.appendSchemaBadge(response['primaryCategory'], $details.find('.js-details-categories'));
  this.appendSchemaBadge(response['hasDescriptionSchema'], $details.find('.js-details-description'));
  this.appendSchemaBadge(response['hasLatLngSchema'], $details.find('.js-details-latLng'));
  this.appendSchemaBadge(response['hasImagesSchema'], $details.find('.js-details-images'));
  this.appendSchemaBadge(response['hasReviewsSchema'], $details.find('.js-details-reviews'));
  this.appendSchemaBadge(response['hasMakesOfferSchema'], $details.find('.js-details-makes-offer'));
  this.appendSchemaBadge(response['aggregateRating'], $details.find('.js-details-aggregate-rating'));

  if (response['hasFall19Release']) {
    this.appendDataBadge(response['matchName'] || !response['name'], $details.find('.js-details-name'));
    this.appendDataBadge(response['matchAddress'] || !response['address'], $details.find('.js-details-address'));
    this.appendDataBadge(response['matchPhone'] || !response['phone'], $details.find('.js-details-phone'));
  }

  return $details;
};

/**
 * @param {ReportView}
 */
yext.locationscan_common.scanresults.ScanResults.prototype.updateStats = function(view) {
  if (view.errors.length) {
    if (view.model.scanError) {
      this.scanErrorCount++; // We dont want to count scan errors as listing errors
    } else {
      this.errorCount++;
      for (var i = 0; i < view.errors.length; i++) {
        if (view.errors[i] == 'listingNotFound') {
          // Set all as error for not found
          this.addressErrorCount++;
          this.nameErrorCount++;
          this.phoneNumberErrorCount++;
        } else if (view.errors[i] == 'address') {
          this.addressErrorCount++;
        } else if (view.errors[i] == 'name') {
          this.nameErrorCount++;
        } else if (view.errors[i] == 'phone') {
          this.phoneNumberErrorCount++;
        }
      }
      var partnerCount = this.partnerCount();
      this.nameErrors.setPercentage(this.nameErrorCount/partnerCount * 100);
      this.addressErrors.setPercentage(this.addressErrorCount/partnerCount * 100);
      this.phoneErrors.setPercentage(this.phoneNumberErrorCount/partnerCount * 100);
    }
    this.renderOverallErrorRate();
  }
};

yext.locationscan_common.scanresults.ScanResults.prototype.renderReviewStats = function() {
  this.countPercent.setPercentage(this.averageCountPercentile);
  this.ratingPercent.setPercentage(this.averageRatingPercentile);

  var percentage = 0;
  if (this.hasCount && this.hasScore) {
    percentage = (this.averageCountPercentile + this.averageRatingPercentile) / 2;
    $('.js-review-percentile').show();
    $('.js-count-percentile').show();
  } else if (this.hasCount) {
    percentage = this.averageCountPercentile;
    $('.js-review-percentile').hide();
  } else if (this.hasScore) {
    percentage = this.averageRatingPercentile;
    $('.js-count-percentile').hide();
  }

  if (this.averageCountPercentile == -1 && this.averageRatingPercentile == -1) {
    $('.js-reviews-level').hide();
  } else {
    $('.js-reviews-level').show();
  }

  var $reviewsRatePercentage = this.$reviewsLevel.find('.js-reviews-rate-percentage');
  $reviewsRatePercentage.html(Math.floor(percentage) + '%');

  if (percentage >= yext.locationscan_common.scanresults.ERROR_RATE_BOUNDARIES.ERROR) {
    this.$reviewsLevel.removeClass('success warning').addClass('error');
  } else if (percentage >= yext.locationscan_common.scanresults.ERROR_RATE_BOUNDARIES.WARNING) {
    this.$reviewsLevel.removeClass('success error').addClass('warning');
  } else {
    this.$reviewsLevel.removeClass('warning error').addClass('success');
  }

  if (this.hasBaseYextCategoryId) {
    if (this.location.displayState) {
      this.$reviewsStatsLabel.html(
        // TRANSLATORS: This sentence is preceded by a percentage and {0} is a US state name.
        // EXAMPLE: 20% of similar businesses in New York have better reviews.
        yext.msg('of similar businesses in {0} <span class=\'avoid-wrap\'>have better reviews</span>',
          this.location.displayState));
    } else {
      this.$reviewsStatsLabel.html(
        // TRANSLATORS: This sentence is preceded by a percentage.
        // EXAMPLE: 20% of similar businesses have better reviews.
        yext.msg('of similar businesses <span class=\'avoid-wrap\'>have better reviews</span>'));
    }
  } else {
    if (this.location.displayState) {
      this.$reviewsStatsLabel.html(
        // TRANSLATORS: This sentence is preceded by a percentage and {0} is a US state name.
        // EXAMPLE: 20% of businesses in New York have better reviews.
        yext.msg('of businesses in {0} <span class=\'avoid-wrap\'>have better reviews</span>',
          this.location.displayState));
    } else {
      this.$reviewsStatsLabel.html(
        // TRANSLATORS: This sentence is preceded by a percentage.
        // EXAMPLE: 20% of businesses have better reviews.
        yext.msg('of businesses <span class=\'avoid-wrap\'>have better reviews</span>'));
    }
  }
};

yext.locationscan_common.scanresults.ScanResults.prototype.renderNoReviews = function() {
  this.countPercent.setPercentage(100);
  this.ratingPercent.setPercentage(100);

  var $reviewsRatePercentage = this.$reviewsLevel.find('.js-reviews-rate-percentage');
  $reviewsRatePercentage.html('X');
  this.$reviewsLevel.removeClass('success warning').addClass('error');

  this.$reviewsStatsLabel.html(yext.msg('No reviews were found </br> anywhere on the web'));
};

yext.locationscan_common.scanresults.ScanResults.prototype.renderProgress = function() {
  this.finishedScans++;
  if (this.finishedScans == this.totalScans) {
    this.switchToLocationDetails();
  } else {
    var percentage = this.finishedScans / this.totalScans * 100;
    this.$scanProgress.find('.js-indicator').css('width', percentage + '%');
  }
};

yext.locationscan_common.scanresults.ScanResults.prototype.switchToLocationDetails = function() {
  var self = this;
  var $container = $('.js-location-details-container');

  this.$scanProgress.on('webkitTransitionEnd transitionend', function() {
    var containerHeight = $container.height();
    self.$scanProgress.hide();
    self.$locationDetails.removeAttr('style');
    var newContainerHeight = $container.height();
    $container.css('height', containerHeight + 'px');

    yext.locationscan_common.scanresults.enableButtons(self.email);

    // Not sure why this hack is needed
    setTimeout(function() {
      self.$locationDetails.css('opacity', 1);
      $container.css({
        'transition-property': 'height',
        'height': newContainerHeight + 'px',
      });
    }, 0);
    self.$scanProgress.off('webkitTransitionEnd transitionend');
  });

  this.$scanProgress.css('opacity', 0);
};

yext.locationscan_common.scanresults.ScanResults.prototype.partnerCount = function() {
  return this.partners.length - this.scanErrorCount;
};

yext.locationscan_common.scanresults.ScanResults.prototype.renderOverallErrorRate = function() {
  var percentage = Math.floor(this.errorCount/this.partnerCount() * 100);
  var $errorRatePercentage = this.$inaccuracyLevel.find('.js-error-rate-percentage');
  $errorRatePercentage.html(percentage);

  if (percentage >= yext.locationscan_common.scanresults.ERROR_RATE_BOUNDARIES.ERROR) {
    this.$inaccuracyLevel.removeClass('success warning').addClass('error');
  } else if (percentage >= yext.locationscan_common.scanresults.ERROR_RATE_BOUNDARIES.WARNING) {
    this.$inaccuracyLevel.removeClass('success').addClass('warning');
  }
};

/**
 * @param {!jQuery} $locationDetails The location details header
 */
yext.locationscan_common.scanresults.bindShowLocationDetails = function($locationDetails) {
  $locationDetails.on('click', function() {
    $locationDetails.find('.js-address').toggle();
    $locationDetails.toggleClass('panel-open');
  });
};

yext.locationscan_common.scanresults.enableButtons = function(email) {
  var $emailIcon = $('.img-email');
  $emailIcon.css('opacity', 1);
  $emailIcon.css('cursor', 'pointer');
  $emailIcon.removeAttr('title');
  $emailIcon.on('click', function() {
    window.location = 'mailto:' + email;
  });

  var $printIcon = $('.img-print');
  $printIcon.css('opacity', 1);
  $printIcon.css('cursor', 'pointer');
  $printIcon.removeAttr('title');
  $printIcon.on('click', function() {
    window.print();
  });

  var html = yext.locationscan_common.scanresults.TEMPLATES.SHARE_SCAN;
  var $details = $(html);

  var $shareIcon = $('.img-share');
  $shareIcon.css('cursor', 'pointer');
  $shareIcon.removeAttr('title');
  $shareIcon.css('opacity', 1);
  $shareIcon.on('click', function() {
    yext.locationscan_common.scanresults.DIALOG_WHOLE_SCREEN.setContent($details).show();
    yext.locationscan_common.scanresults.ScanResults.prototype.bindAutosizingTextAreas();
  });
};

/**
 * Scan Report model
 *
 * @constructor
 */
yext.locationscan_common.scanresults.Report = function(data, websiteSupported, hoursSupported, categorySupported) {
  this.partnerId = data['partnerId'];
  this.brandId = data['brandId'];
  this.name = data['name'];
  this.phone = data['phone'];
  this.addressLine = data['addressLine'];
  this.listingUrl = data['listingUrl'];
  this.listingFound = data['listingFound'];
  this.hasCategory = data['hasCategory'];
  this.hasDescription = data['hasDescription'];
  this.hasPhoto = data['hasPhoto'];
  this.hasPowerlistings = data['hasPowerlistings'];
  this.hasSite = data['hasSite'];
  this.hasSpecialOffer = data['hasSpecialOffer'];
  this.hideFeaturedMessage = data['hideFeaturedMessage'];
  this.hasVideo = data['hasVideo'];
  this.matchAddress = data['matchAddress'];
  this.matchName = data['matchName'];
  this.matchPhone = data['matchPhone'];
  this.scanError = data['scanError'];
  this.reviewCount = data['reviewCount'];
  this.hasReviewCount = data['hasReviewCount'];
  this.averageReviewScore = data['averageReviewScore'];
  this.ratingPercentile = data['reviewScorePercentile'];
  this.countPercentile = data['reviewCountPercentile'];
  this.businessWebsiteUrl = data['businessWebsiteUrl'];
  this.hasHours = data['hasHours'];
  this.primaryCategory = data['primaryCategory'];
  this.hasComparativeData = this.ratingPercentile !== undefined || this.countPercentile !== undefined;
  this.websiteSupported = websiteSupported;
  this.hoursSupported = hoursSupported;
  this.categorySupported = categorySupported;
};

/**
 * Scan Report view model
 *
 * @constructor
 * @param {Report} model Report model
 * @param {string} brandName
 * @param {int} appVersion
 * @param {string} productName
 * @param {boolean} whether the scan shows review data or not
 * @param {int} the Yext location id of the scanned business
 * @param {boolean} whether to suppress display of name address and phone info
 * @param {boolean} whether the scan was a partner scan
 */
yext.locationscan_common.scanresults.ReportView = function(
  model,
  brandName,
  appVersion,
  productName,
  showReviews,
  locationId,
  suppressNap,
  isPartnerScan) {
  this.model = model;
  this.brandName = brandName;
  this.appVersion = appVersion;
  this.productName = productName;
  this.showReviews = showReviews;
  this.locationId = locationId;
  this.suppressNap = suppressNap;
  this.isPartnerScan = isPartnerScan;
  this.errors = this.getErrors();
  this.warnings = this.getWarnings();
  this.$ctx = this.getContext();

  this.render();
};

yext.locationscan_common.scanresults.ReportView.prototype.getContext = function() {
  return $('.js-partner-list').find(
    'li[data-id=' + this.model.partnerId + '][data-brand-id=' + this.model.brandId + ']');
};

yext.locationscan_common.scanresults.ReportView.prototype.renderIndividualPercentile =
    function(percent, className) {
      var obj = this.$ctx.find('span[class*=\'' + className + '\']');
      if (obj) {
        if (this.model.averageReviewScore > 4.9
        && className === yext.locationscan_common.scanresults.RATING_PERCENT_CLASS_NAME) {
          obj.addClass('yext-green-class');
        } else if (this.model.reviewCount == 0
      && (className === yext.locationscan_common.scanresults.COUNT_PERCENT_CLASS_NAME
       || className === yext.locationscan_common.scanresults.RATING_PERCENT_CLASS_NAME)) {
          obj.addClass('yext-red-class');
        } else if (percent >= yext.locationscan_common.scanresults.ERROR_RATE_BOUNDARIES.ERROR) {
          obj.addClass('yext-red-class');
        } else if (percent >= yext.locationscan_common.scanresults.ERROR_RATE_BOUNDARIES.WARNING) {
          obj.addClass('yext-orange-class');
        } else {
          obj.addClass('yext-green-class');
        }
      }
    };

yext.locationscan_common.scanresults.ReportView.prototype.render = function() {
  var html;
  // If the scanned business has PowerListings and the partner has the reviews feature,
  // we only display publishers with review data.
  if (!this.model.averageReviewScore && !this.model.hasReviewCount
      && this.suppressNap && this.showReviews) {
    this.$ctx.remove();
    return;
  }

  if (this.model.scanError) {
    html = yext.msg('There was a temporary problem scanning on this publisher');
  } else {
    if (this.model.hasPowerlistings
        && (!this.showReviews || !this.model.averageReviewScore && !this.model.hasReviewCount)) {
      var syncedMessage = '';
      if (this.model.hasPowerlistings && this.productName) {
        syncedMessage = yext.msg('{0} Synced', this.productName);
      } else {
        syncedMessage = yext.msg('Powerlistings Synced');
      }
      html = yext.templates.render(yext.locationscan_common.scanResultsTemplates.syncedResults,
        {syncedMessage: syncedMessage ? syncedMessage: ''});

      var $html = $(html);
      $html.find('.js-synced-message').prepend(yext.locationscan_common.scanresults.SCAN_ICONS.CORRECT_FIELD.cloneNode());
      html = $html.html();
    } else {
      html = yext.templates.render(yext.locationscan_common.scanResultsTemplates.scanResults,
        {showReviews: this.showReviews,
          model: this.model,
          roundedScore: this.getRoundedScoreText(),
          starScore: this.getStarScore()});
    }
  }
  this.$ctx.find('.js-scan-results').html(html);

  if (!this.model.scanError) {
    this.renderIndividualPercentile(this.model.ratingPercentile, yext.locationscan_common.scanresults.RATING_PERCENT_CLASS_NAME);
    this.renderIndividualPercentile(this.model.countPercentile, yext.locationscan_common.scanresults.COUNT_PERCENT_CLASS_NAME);
  }

  var $nameField = this.$ctx.find('.js-partner-location-name');
  var $addressField = this.$ctx.find('.js-partner-location-address');
  var $phoneField = this.$ctx.find('.js-partner-location-phone');
  var $missingListing = this.$ctx.find('.js-missing-listing');
  var $reviewScoreField = this.$ctx.find('.js-review-score');
  var $reviewCountField = this.$ctx.find('.js-review-count');
  var $partnerLocationField = this.$ctx.find('.js-partner-location');

  if (this.model.listingFound) {
    this.renderFieldStatusIcon(this.model.matchName, $nameField);
    this.renderFieldStatusIcon(this.model.matchAddress, $addressField);
    this.renderFieldStatusIcon(this.model.matchPhone, $phoneField);
    $missingListing.remove();
  } else {
    this.renderFieldStatusIcon(false, $missingListing);
    $nameField.remove();
    $addressField.remove();
    $phoneField.remove();
  }

  if (this.suppressNap && this.showReviews) {
    $partnerLocationField.remove();
  }

  if (!this.model.averageReviewScore) {
    $reviewScoreField.remove();
  }
  if (!this.model.hasReviewCount) {
    $reviewCountField.remove();
  }

  if (!this.model.name) {
    $nameField.addClass('listing-error-text');
  }
  if (!this.model.addressLine) {
    $addressField.addClass('listing-error-text');
  }
  if (!this.model.phone) {
    $phoneField.addClass('listing-error-text');
  }
};


/**
 * Prepends a check or an 'x' to a publiser card field (e.g. address) depending on whether
 * it is correct or not
 *
 * @param {boolean} isCorrect whether the field is correct or not
 * @param {!Object} $field which location field to prepend the image onto
 */
yext.locationscan_common.scanresults.ReportView.prototype.renderFieldStatusIcon = function(isCorrect, $field) {
  var statusImg = isCorrect
    ? yext.locationscan_common.scanresults.SCAN_ICONS.CORRECT_FIELD : yext.locationscan_common.scanresults.SCAN_ICONS.INCORRECT_FIELD;

  $field.prepend(statusImg.cloneNode());
};

yext.locationscan_common.scanresults.ReportView.prototype.setDetailsUrl = function(location, embedded, locationId, platform) {
  if (!this.model.scanError) {
    var href = 'publisherDetails?location=' + encodeURIComponent(location) + '&partnerId=' +
      this.model.partnerId + '&brandId=' + this.model.brandId + '&embedded=' + embedded;
    if (platform) {
      href += '&platform=' + platform;
    }
    if (locationId) {
      href += '&locationId=' + locationId;
    }
    this.$ctx.find('a').attr('href', href);
  }
};

yext.locationscan_common.scanresults.ReportView.prototype.bindDetailsPopup = function() {
  var $details = this.templateDetails();
  this.$ctx.find('a').on('click', function() {
    yext.locationscan_common.scanresults.DIALOG_BOX.setContent($details).show();
  });
};

yext.locationscan_common.scanresults.ReportView.prototype.getRoundedScoreText = function() {
  if (this.model.averageReviewScore) {
    var roundedScore = (Math.round(this.model.averageReviewScore * 10) / 10).toFixed(1);
    return '' + roundedScore;
  }
  return '0.0';
};

yext.locationscan_common.scanresults.ReportView.prototype.getStarScore = function() {
  if (this.model.averageReviewScore) {
    if (this.model.partnerId == 439 || this.model.partnerId == 588) {
      return Math.round(this.model.averageReviewScore * 2) * 5;
    }
    return Math.round(this.model.averageReviewScore * 2) / 2;
  }
  return 0;
};

yext.locationscan_common.scanresults.ReportView.prototype.templateDetails = function() {
  var partnerIconSrc = this.$ctx.find('.js-partner-icon').attr('src');
  var badge = null;
  var $details = null;
  if (this.model.hasPowerlistings
      && (!this.showReviews || !this.model.averageReviewScore && !this.model.hasReviewCount)) {
    var html = yext.templates.render(yext.locationscan_common.scanResultsTemplates.scanDetailsSynced,
      {
        powerListingPluralProductName: this.productName,
        brandName: this.brandName,
        partnerIconSrc: partnerIconSrc,
        errors: this.errors,
        warnings: this.warnings,
      });
    $details = $(html);
  } else if (this.model.hasPowerlistings) {
    var html = yext.templates.render(yext.locationscan_common.scanResultsTemplates.scanDetails,
      {partnerIconSrc: partnerIconSrc,
        brandName: this.brandName,
        model: this.model,
        roundedScore: this.getRoundedScoreText(),
        starScore: this.getStarScore()});

    $details = $(html);

    $details.find('.js-details-address').remove();
    $details.find('.js-details-name').remove();
    $details.find('.js-details-phone').remove();
    $details.find('.js-details-category').remove();
    $details.find('.js-details-website').remove();
    $details.find('.js-details-hours').remove();

    if (!this.model.listingFound || !this.model.listingUrl) {
      $details.find('.js-view-listing').remove();
    }
  } else {
    var html = yext.templates.render(yext.locationscan_common.scanResultsTemplates.scanDetails,
      {partnerIconSrc: partnerIconSrc,
        brandName: this.brandName,
        showReviews: this.showReviews,
        isPartnerScan: this.isPartnerScan,
        model: this.model,
        errors: this.errors,
        warnings: this.warnings,
        productName: this.productName,
        roundedScore: this.getRoundedScoreText(),
        starScore: this.getStarScore()});

    $details = $(html);

    if (!this.model.hasReviewCount && !this.model.averageReviewScore) {
      $details.find('.js-reviews-info').remove();
    }

    if (!this.model.listingFound || !this.model.listingUrl) {
      $details.find('.js-view-listing').remove();
    }

    if (!this.model.matchAddress) {
      badge = this.templateBadge('error', yext.msg('Wrong address'));
      $details.find('.js-details-address')
        .addClass('no-match')
        .append(badge);
    }

    if (!this.model.matchName) {
      badge = this.templateBadge('error', yext.msg('Wrong name'));
      $details.find('.js-details-name')
        .addClass('no-match')
        .append(badge);
    }

    if (!this.model.matchPhone) {
      badge = this.templateBadge('error', yext.msg('Wrong phone'));
      $details.find('.js-details-phone')
        .addClass('no-match')
        .append(badge);
    }

    if (!this.model.primaryCategory) {
      badge = this.templateBadge('error', yext.msg('Wrong category'));
      $details.find('.js-details-category')
        .addClass('no-match')
        .append(badge);
    }

    if (!this.model.businessWebsiteUrl) {
      badge = this.templateBadge('error', yext.msg('Wrong website'));
      $details.find('.js-details-website')
        .addClass('no-match')
        .append(badge);
    }

    if (!this.model.hasHours) {
      badge = this.templateBadge('error', yext.msg('Wrong hours'));
      $details.find('.js-details-hours')
        .addClass('no-match')
        .append(badge);
    }
  }

  return $details;
};

yext.locationscan_common.scanresults.ReportView.prototype.templateBadge = function(type, text) {
  return '<label class="badge ' + type + '">' + text + '</label>';
};

yext.locationscan_common.scanresults.ReportView.prototype.getErrors = function() {
  var errors = [];
  if (!this.model.listingFound) {
    errors.push('listingNotFound');
    return errors;
  }
  if (!this.model.matchAddress) {
    errors.push('address');
  }
  if (!this.model.matchName) {
    errors.push('name');
  }
  if (!this.model.matchPhone) {
    errors.push('phone');
  }
  return errors;
};

/**
 * TODO(mattjs) Should fields other than special offer trigger not standing out?
 */
yext.locationscan_common.scanresults.ReportView.prototype.getWarnings = function() {
  var warnings = [];
  /* if (!this.model.hasCategory) {
    warnings.push('category');
  }
  if (!this.model.hasDescription) {
    warnings.push('description');
  }
  if (!this.model.hasPhoto) {
    warnings.push('photo');
  }
  if (!this.model.hasSite) {
    warnings.push('site');
  }*/
  if (!this.model.hasSpecialOffer && !(this.model.hideFeaturedMessage === true)) {
    warnings.push('specialOffer');
  }
  /* if (!this.model.hasVideo) {
    warnings.push('video');
  }*/
  return warnings;
};

/**
 * @constructor
 */
yext.locationscan_common.PublisherDetailsView = function() {
  yext.locationscan_common.scanresults.bindShowLocationDetails($('.js-location-details'));
  this.positionListingLink();
};
goog.exportSymbol(
  'yext.locationscan_common.PublisherDetailsView',
  yext.locationscan_common.PublisherDetailsView);

yext.locationscan_common.PublisherDetailsView.prototype.positionListingLink = function() {
  var $viewListing = $('.js-view-listing');

  if ($viewListing.length) {
    var windowHeight = $(window).height();
    var detailsHeight = Math.ceil($('.js-scan-result-details').outerHeight());

    if (detailsHeight < windowHeight) {
      var margin = windowHeight - detailsHeight;
      $viewListing.css('marginTop', margin);
    }
    $viewListing.css('opacity', 1);
  }
};
