// This file was automatically generated by the Soy compiler.
// Please don't edit this file by hand.
// source: js/yext/locationscan-common/scanResultsTemplates.soy

/**
 * @fileoverview Templates in namespace yext.locationscan_common.scanResultsTemplates.
 * @public
 */

goog.provide('yext.locationscan_common.scanResultsTemplates');
goog.require("yext");

goog.require('goog.i18n.MessageFormat');
goog.require('goog.soy');
goog.require('soy');
goog.require('soydata.VERY_UNSAFE');


/**
 * @param {!yext.locationscan_common.scanResultsTemplates.scanResults.Params} opt_data
 * @param {(?goog.soy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog.soy.data.SanitizedHtml}
 * @suppress {checkTypes}
 */
yext.locationscan_common.scanResultsTemplates.scanResults = function(opt_data, opt_ijData) {
  opt_ijData = /** @type {!goog.soy.IjData} */ (opt_ijData);
  /** @type {?} */
  const showReviews = opt_data.showReviews;
  /** @type {?} */
  const model = opt_data.model;
  /** @type {?} */
  const roundedScore = opt_data.roundedScore;
  /** @type {?} */
  const starScore = opt_data.starScore;
  let $output = '<div class="scan-results-template"' + (goog.DEBUG && soy.$$debugSoyTemplateInfo ? ' data-debug-soy="yext.locationscan_common.scanResultsTemplates.scanResults js/yext/locationscan-common/scanResultsTemplates.soy:16"' : '') + '><div class="card-container partner-location js-partner-location"><div class="js-missing-listing listing-error-text">';
  /** @desc  */
  const MSG_UNNAMED = yext.getMsg('Listing Not Found', {}, {html: true});
  $output += MSG_UNNAMED;
  $output += '</div><div class="js-partner-location-name partner-location-name">';
  if (model.name) {
    $output += soy.$$escapeHtml(model.name);
  } else {
    /** @desc  */
    const MSG_UNNAMED$$1 = yext.getMsg('No name', {}, {html: true});
    $output += MSG_UNNAMED$$1;
  }
  $output += '</div><div class="js-partner-location-address">';
  if (model.addressLine) {
    $output += soy.$$escapeHtml(model.addressLine);
  } else {
    /** @desc  */
    const MSG_UNNAMED$$2 = yext.getMsg('No address', {}, {html: true});
    $output += MSG_UNNAMED$$2;
  }
  $output += '</div><div class="js-partner-location-phone">';
  if (model.phone) {
    $output += soy.$$escapeHtml(model.phone);
  } else {
    /** @desc  */
    const MSG_UNNAMED$$3 = yext.getMsg('No phone number', {}, {html: true});
    $output += MSG_UNNAMED$$3;
  }
  $output += '</div></div>';
  if (showReviews) {
    $output += '<div class="js-review-score review-info">';
    if ((soy.$$equals(model.partnerId, 439) || soy.$$equals(model.partnerId, 588)) && soy.$$coerceToBoolean(model.averageReviewScore)) {
      $output += '<t class="star-text">' + soy.$$escapeHtml(roundedScore) + '</t><img src="/partner/public/images/yelp_stars/web_and_ios/large/large_' + soy.$$escapeHtmlAttribute(Math.floor(starScore / 10)) + (!soy.$$equals(starScore % 10, 0) ? '_half' : '') + '@2x.png" class="yelp-stars"/>';
    } else if (model.averageReviewScore) {
      $output += '<t class="star-text">' + soy.$$escapeHtml(roundedScore) + '</t>';
      const i117ListLen = Math.max(0, Math.ceil((5 - 0) / 1));
      for (let i117Index = 0; i117Index < i117ListLen; i117Index++) {
        const i117Data = 0 + i117Index * 1;
        let $tmp;
        if (starScore - i117Data >= 1) {
          $tmp = '<img src="/partner/public/images/icon-star-filled.svg" class="star"/>';
        } else if (starScore - i117Data >= 0.5) {
          $tmp = '<img src="/partner/public/images/icon-star-half-filled.svg" class="star"/>';
        } else {
          $tmp = '<img src="/partner/public/images/icon-star-empty.svg" class="star"/>';
        }
        $output += $tmp;
      }
    }
    $output += '<span class="float-right' + (soy.$$coerceToBoolean(model.hasComparativeData) || soy.$$equals(model.reviewCount, 0) ? ' rating-percent' : '') + '">';
    if (soy.$$equals(model.reviewCount, 0)) {
      /** @desc  */
      const MSG_UNNAMED$$4 = yext.getMsg('YOU HAVE NO REVIEWS', {}, {html: true});
      $output += MSG_UNNAMED$$4;
    } else if (model.averageReviewScore > 4.9) {
      /** @desc  */
      const MSG_UNNAMED$$5 = yext.getMsg('PERFECT RATING!', {}, {html: true});
      $output += MSG_UNNAMED$$5;
    } else if (soy.$$coerceToBoolean(model.hasComparativeData) && soy.$$equals(model.ratingPercentile, 0)) {
      /** @desc '<' means 'less than' */
      const MSG_UNNAMED$$6 = yext.getMsg('&lt; 1% ARE RATED HIGHER', {}, {html: true});
      $output += MSG_UNNAMED$$6;
    } else if (soy.$$equals(model.ratingPercentile, 100)) {
      /** @desc '>' means 'greater than' */
      const MSG_UNNAMED$$7 = yext.getMsg('&gt; 99% ARE RATED HIGHER', {}, {html: true});
      $output += MSG_UNNAMED$$7;
    } else if (model.ratingPercentile > 0) {
      /** @desc {$model.ratingPercentile} is a number between 0 and 100. Example: 9% ARE RATED HIGHER */
      const MSG_UNNAMED$$8 = yext.getMsg('{$ratingPercentile}% ARE RATED HIGHER', {'ratingPercentile': soy.$$escapeHtml(model.ratingPercentile)}, {html: true});
      $output += MSG_UNNAMED$$8;
    } else if (!model.hasComparativeData) {
      /** @desc  */
      const MSG_UNNAMED$$9 = yext.getMsg('NO COMPARATIVE DATA', {}, {html: true});
      $output += MSG_UNNAMED$$9;
    }
    $output += '</span></div><div class="js-review-count review-info">';
    if (model.hasReviewCount) {
      $output += '<t class="review-text">';
      /** @desc  */
      const MSG_UNNAMED$$10 = yext.getMsg('{REVIEW_COUNT_1,plural,=1{ 1 Review}other{ {REVIEW_COUNT_2} Reviews}}', {}, {html: true});
      const msg_s$$10 = new goog.i18n.MessageFormat(MSG_UNNAMED$$10).formatIgnoringPound({'REVIEW_COUNT_1': model.reviewCount, 'REVIEW_COUNT_2': soy.$$escapeHtml(model.reviewCount)});
      $output += msg_s$$10;
      $output += '</t>';
    }
    $output += '<span class="float-right' + (soy.$$coerceToBoolean(model.hasComparativeData) || soy.$$equals(model.reviewCount, 0) ? ' count-percent' : '') + '">';
    if (soy.$$equals(model.reviewCount, 0)) {
      /** @desc  */
      const MSG_UNNAMED$$11 = yext.getMsg('YOU HAVE NO REVIEWS', {}, {html: true});
      $output += MSG_UNNAMED$$11;
    } else if (soy.$$equals(model.countPercentile, 0)) {
      /** @desc '<' means 'less than' */
      const MSG_UNNAMED$$12 = yext.getMsg('&lt; 1% HAVE MORE REVIEWS', {}, {html: true});
      $output += MSG_UNNAMED$$12;
    } else if (soy.$$equals(model.countPercentile, 100)) {
      /** @desc '>' means 'greater than' */
      const MSG_UNNAMED$$13 = yext.getMsg('&gt; 99% HAVE MORE REVIEWS', {}, {html: true});
      $output += MSG_UNNAMED$$13;
    } else if (model.countPercentile > 0) {
      /** @desc {$model.countPercentile} is a number between 0 and 100. Example: 42% HAVE MORE REVIEWS */
      const MSG_UNNAMED$$14 = yext.getMsg('{$countPercentile}% HAVE MORE REVIEWS', {'countPercentile': soy.$$escapeHtml(model.countPercentile)}, {html: true});
      $output += MSG_UNNAMED$$14;
    } else if (!model.hasComparativeData) {
      /** @desc  */
      const MSG_UNNAMED$$15 = yext.getMsg('NO COMPARATIVE DATA', {}, {html: true});
      $output += MSG_UNNAMED$$15;
    }
    $output += '</span></div>';
  }
  $output += '</div>';
  return soydata.VERY_UNSAFE.ordainSanitizedHtml($output);
};
/**
 * @typedef {{
 *  showReviews: ?,
 *  model: ?,
 *  roundedScore: (?|undefined),
 *  starScore: (?|undefined),
 * }}
 */
yext.locationscan_common.scanResultsTemplates.scanResults.Params;
if (goog.DEBUG) {
  /** @type {string} */
  yext.locationscan_common.scanResultsTemplates.scanResults.soyTemplateName = 'yext.locationscan_common.scanResultsTemplates.scanResults';
}


/**
 * @param {!yext.locationscan_common.scanResultsTemplates.scanDetails.Params} opt_data
 * @param {(?goog.soy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog.soy.data.SanitizedHtml}
 * @suppress {checkTypes}
 */
yext.locationscan_common.scanResultsTemplates.scanDetails = function(opt_data, opt_ijData) {
  opt_ijData = /** @type {!goog.soy.IjData} */ (opt_ijData);
  /** @type {?} */
  const partnerIconSrc = opt_data.partnerIconSrc;
  /** @type {?} */
  const brandName = opt_data.brandName;
  /** @type {?} */
  const showReviews = opt_data.showReviews;
  /** @type {?} */
  const isPartnerScan = opt_data.isPartnerScan;
  /** @type {?} */
  const model = opt_data.model;
  /** @type {?} */
  const errors = opt_data.errors;
  /** @type {?} */
  const warnings = opt_data.warnings;
  /** @type {?} */
  const productName = opt_data.productName;
  /** @type {?} */
  const roundedScore = opt_data.roundedScore;
  /** @type {?} */
  const starScore = opt_data.starScore;
  let $output = '<div id="scan-result-details"' + (goog.DEBUG && soy.$$debugSoyTemplateInfo ? ' data-debug-soy="yext.locationscan_common.scanResultsTemplates.scanDetails js/yext/locationscan-common/scanResultsTemplates.soy:114"' : '') + '><div class="scan-details"><section class="publisher-info"><h4>';
  /** @desc  */
  const MSG_UNNAMED = yext.getMsg('Publisher', {}, {html: true});
  $output += MSG_UNNAMED;
  $output += '</h4><div class="partner-icon-container js-partner-icon-container"><img class="partner-icon" src="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeMediaUri(partnerIconSrc)) + '"></div><div class="partner-details"><p>' + soy.$$escapeHtml(brandName) + '</p><p class="js-details-badge">';
  if (soy.$$coerceToBoolean(errors) && soy.$$coerceToBoolean(warnings)) {
    $output += '<label class="badge ' + (!model.hasPowerListings && errors.length > 0 ? 'error' : 'success') + '">';
    if (!model.hasPowerListings && errors.length > 1) {
      /** @desc  */
      const MSG_UNNAMED$$1 = yext.getMsg('Multiple Errors ({$length})', {'length': soy.$$escapeHtml(errors.length)}, {html: true});
      $output += MSG_UNNAMED$$1;
    } else if (!model.hasPowerListings && soy.$$equals(errors.length, 1)) {
      if (soy.$$equals(errors[/** @type {?} */ (0)], 'listingNotFound')) {
        /** @desc  */
        const MSG_UNNAMED$$2 = yext.getMsg('Missing listing', {}, {html: true});
        $output += MSG_UNNAMED$$2;
      } else if (soy.$$equals(errors[/** @type {?} */ (0)], 'address')) {
        /** @desc  */
        const MSG_UNNAMED$$3 = yext.getMsg('Wrong address', {}, {html: true});
        $output += MSG_UNNAMED$$3;
      } else if (soy.$$equals(errors[/** @type {?} */ (0)], 'name')) {
        /** @desc  */
        const MSG_UNNAMED$$4 = yext.getMsg('Different business name', {}, {html: true});
        $output += MSG_UNNAMED$$4;
      } else if (soy.$$equals(errors[/** @type {?} */ (0)], 'phone')) {
        /** @desc  */
        const MSG_UNNAMED$$5 = yext.getMsg('Different phone number', {}, {html: true});
        $output += MSG_UNNAMED$$5;
      }
    } else {
      if (soy.$$coerceToBoolean(model.hasPowerListings) && soy.$$coerceToBoolean(productName)) {
        /** @desc  */
        const MSG_UNNAMED$$6 = yext.getMsg('{$productName} Synced', {'productName': soy.$$escapeHtml(productName)}, {html: true});
        $output += MSG_UNNAMED$$6;
      } else if (model.hasPowerListings) {
        /** @desc  */
        const MSG_UNNAMED$$7 = yext.getMsg('Powerlistings Synced', {}, {html: true});
        $output += MSG_UNNAMED$$7;
      } else {
        /** @desc  */
        const MSG_UNNAMED$$8 = yext.getMsg('Listed', {}, {html: true});
        $output += MSG_UNNAMED$$8;
      }
    }
    $output += '</label>';
    if (soy.$$equals(errors.length, 0) && soy.$$coerceToBoolean(warnings.length) && soy.$$equals(warnings[/** @type {?} */ (0)], 'specialOffer')) {
      $output += '<label class="badge ">';
      /** @desc  */
      const MSG_UNNAMED$$9 = yext.getMsg('No Special Offer', {}, {html: true});
      $output += MSG_UNNAMED$$9;
      $output += '</label>';
    }
  }
  $output += '</p>';
  if (showReviews) {
    $output += '<p class="js-reviews-info">';
    if ((soy.$$equals(model.partnerId, 439) || soy.$$equals(model.partnerId, 588)) && soy.$$coerceToBoolean(model.averageReviewScore)) {
      $output += '<t class="star-text">' + soy.$$escapeHtml(roundedScore) + '</t><img src="/partner/public/images/yelp_stars/web_and_ios/large/large_' + soy.$$escapeHtmlAttribute(Math.floor(starScore / 10)) + (!soy.$$equals(starScore % 10, 0) ? '_half' : '') + '@2x.png" class="yelp-stars"/>';
    } else if (model.averageReviewScore) {
      $output += '<t class="star-text">' + soy.$$escapeHtml(roundedScore) + '</t>';
      const i435ListLen = Math.max(0, Math.ceil((5 - 0) / 1));
      for (let i435Index = 0; i435Index < i435ListLen; i435Index++) {
        const i435Data = 0 + i435Index * 1;
        let $tmp;
        if (starScore - i435Data >= 1) {
          $tmp = '<img src="/partner/public/images/icon-star-filled.svg" class="star"/>';
        } else if (starScore - i435Data >= 0.5) {
          $tmp = '<img src="/partner/public/images/icon-star-half-filled.svg" class="star"/>';
        } else {
          $tmp = '<img src="/partner/public/images/icon-star-empty.svg" class="star"/>';
        }
        $output += $tmp;
      }
    }
    if (model.hasReviewCount) {
      $output += '<t class="review-text">';
      /** @desc  */
      const MSG_UNNAMED$$10 = yext.getMsg('{REVIEW_COUNT_1,plural,=1{ 1 Review}other{ {REVIEW_COUNT_2} Reviews}}', {}, {html: true});
      const msg_s$$10 = new goog.i18n.MessageFormat(MSG_UNNAMED$$10).formatIgnoringPound({'REVIEW_COUNT_1': model.reviewCount, 'REVIEW_COUNT_2': soy.$$escapeHtml(model.reviewCount)});
      $output += msg_s$$10;
      $output += '</t>';
    }
    $output += '</p>';
  }
  $output += '<a class="btn btn-secondary view-listing js-view-listing" href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri(model.listingUrl)) + '"' + (isPartnerScan ? ' target="_blank"' : '') + ' style="display:none">';
  /** @desc  */
  const MSG_UNNAMED$$11 = yext.getMsg('view listing', {}, {html: true});
  $output += MSG_UNNAMED$$11;
  $output += '</a></div></section><section class="name js-details-name"><h4>';
  /** @desc  */
  const MSG_UNNAMED$$12 = yext.getMsg('Name', {}, {html: true});
  $output += MSG_UNNAMED$$12;
  $output += '</h4><p>';
  if (model.name) {
    $output += soy.$$escapeHtml(model.name);
  } else {
    /** @desc  */
    const MSG_UNNAMED$$13 = yext.getMsg('No name', {}, {html: true});
    $output += MSG_UNNAMED$$13;
  }
  $output += '</p></section><section class="address js-details-address"><h4>';
  /** @desc  */
  const MSG_UNNAMED$$14 = yext.getMsg('Address', {}, {html: true});
  $output += MSG_UNNAMED$$14;
  $output += '</h4><p>';
  if (model.addressLine) {
    $output += soy.$$escapeHtml(model.addressLine);
  } else {
    /** @desc  */
    const MSG_UNNAMED$$15 = yext.getMsg('No address', {}, {html: true});
    $output += MSG_UNNAMED$$15;
  }
  $output += '</p></section><section class="phone js-details-phone"><h4>';
  /** @desc  */
  const MSG_UNNAMED$$16 = yext.getMsg('Phone', {}, {html: true});
  $output += MSG_UNNAMED$$16;
  $output += '</h4><p>';
  if (model.phone) {
    $output += soy.$$escapeHtml(model.phone);
  } else {
    /** @desc  */
    const MSG_UNNAMED$$17 = yext.getMsg('No phone', {}, {html: true});
    $output += MSG_UNNAMED$$17;
  }
  $output += '</p></section>';
  if (model.categorySupported) {
    $output += '<section class="category js-details-category"><h4>';
    /** @desc  */
    const MSG_UNNAMED$$18 = yext.getMsg('Primary Category', {}, {html: true});
    $output += MSG_UNNAMED$$18;
    $output += '</h4><p>';
    if (model.primaryCategory) {
      $output += soy.$$escapeHtml(model.primaryCategory);
    } else {
      /** @desc  */
      const MSG_UNNAMED$$19 = yext.getMsg('No category', {}, {html: true});
      $output += MSG_UNNAMED$$19;
    }
    $output += '</p></section>';
  }
  if (model.websiteSupported) {
    $output += '<section class="website js-details-website"><h4>';
    /** @desc  */
    const MSG_UNNAMED$$20 = yext.getMsg('Website', {}, {html: true});
    $output += MSG_UNNAMED$$20;
    $output += '</h4><p>';
    if (model.businessWebsiteUrl) {
      $output += soy.$$escapeHtml(model.businessWebsiteUrl);
    } else {
      /** @desc  */
      const MSG_UNNAMED$$21 = yext.getMsg('No website', {}, {html: true});
      $output += MSG_UNNAMED$$21;
    }
    $output += '</p></section>';
  }
  if (model.hoursSupported) {
    $output += '<section class="hours js-details-hours"><h4>';
    /** @desc  */
    const MSG_UNNAMED$$22 = yext.getMsg('Hours', {}, {html: true});
    $output += MSG_UNNAMED$$22;
    $output += '</h4><p>';
    if (model.hasHours) {
      /** @desc  */
      const MSG_UNNAMED$$23 = yext.getMsg('Listed', {}, {html: true});
      $output += MSG_UNNAMED$$23;
    } else {
      /** @desc  */
      const MSG_UNNAMED$$24 = yext.getMsg('No hours', {}, {html: true});
      $output += MSG_UNNAMED$$24;
    }
    $output += '</p></section>';
  }
  $output += '<div class="view-listing js-view-listing"><a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri(model.listingUrl)) + '" class="btn btn-secondary"' + (isPartnerScan ? ' target="_blank"' : '') + '>';
  /** @desc  */
  const MSG_UNNAMED$$25 = yext.getMsg('View Listing', {}, {html: true});
  $output += MSG_UNNAMED$$25;
  $output += '</a></div></div></div>';
  return soydata.VERY_UNSAFE.ordainSanitizedHtml($output);
};
/**
 * @typedef {{
 *  partnerIconSrc: ?,
 *  brandName: ?,
 *  showReviews: (?|undefined),
 *  isPartnerScan: (?|undefined),
 *  model: ?,
 *  errors: (?|undefined),
 *  warnings: (?|undefined),
 *  productName: (?|undefined),
 *  roundedScore: (?|undefined),
 *  starScore: (?|undefined),
 * }}
 */
yext.locationscan_common.scanResultsTemplates.scanDetails.Params;
if (goog.DEBUG) {
  /** @type {string} */
  yext.locationscan_common.scanResultsTemplates.scanDetails.soyTemplateName = 'yext.locationscan_common.scanResultsTemplates.scanDetails';
}


/**
 * @param {!yext.locationscan_common.scanResultsTemplates.schemaResultsTemplate.Params} opt_data
 * @param {(?goog.soy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog.soy.data.SanitizedHtml}
 * @suppress {checkTypes}
 */
yext.locationscan_common.scanResultsTemplates.schemaResultsTemplate = function(opt_data, opt_ijData) {
  opt_ijData = /** @type {!goog.soy.IjData} */ (opt_ijData);
  /** @type {?} */
  const name = opt_data.name;
  /** @type {?} */
  const address = opt_data.address;
  /** @type {?} */
  const phone = opt_data.phone;
  /** @type {?} */
  const reviews = opt_data.reviews;
  /** @type {?} */
  const hasFall19Release = opt_data.hasFall19Release;
  return soydata.VERY_UNSAFE.ordainSanitizedHtml('<div class="card-container schema-card js-partner-location"' + (goog.DEBUG && soy.$$debugSoyTemplateInfo ? ' data-debug-soy="yext.locationscan_common.scanResultsTemplates.schemaResultsTemplate js/yext/locationscan-common/scanResultsTemplates.soy:238"' : '') + '><div class="js-schema-name">' + soy.$$escapeHtml(name) + '</div><div class="js-schema-address">' + soy.$$escapeHtml(address) + '</div><div class="js-schema-phone">' + soy.$$escapeHtml(phone) + '</div>' + (!hasFall19Release ? '<div class="js-schema-reviews">' + soy.$$escapeHtml(reviews) + '</div>' : '') + '</div>');
};
/**
 * @typedef {{
 *  name: ?,
 *  address: ?,
 *  phone: ?,
 *  reviews: ?,
 *  hasFall19Release: ?,
 * }}
 */
yext.locationscan_common.scanResultsTemplates.schemaResultsTemplate.Params;
if (goog.DEBUG) {
  /** @type {string} */
  yext.locationscan_common.scanResultsTemplates.schemaResultsTemplate.soyTemplateName = 'yext.locationscan_common.scanResultsTemplates.schemaResultsTemplate';
}


/**
 * @param {!yext.locationscan_common.scanResultsTemplates.syncedResults.Params} opt_data
 * @param {(?goog.soy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog.soy.data.SanitizedHtml}
 * @suppress {checkTypes}
 */
yext.locationscan_common.scanResultsTemplates.syncedResults = function(opt_data, opt_ijData) {
  opt_ijData = /** @type {!goog.soy.IjData} */ (opt_ijData);
  /** @type {?} */
  const syncedMessage = opt_data.syncedMessage;
  return soydata.VERY_UNSAFE.ordainSanitizedHtml('<div class="js-badge-container badge-container "' + (goog.DEBUG && soy.$$debugSoyTemplateInfo ? ' data-debug-soy="yext.locationscan_common.scanResultsTemplates.syncedResults js/yext/locationscan-common/scanResultsTemplates.soy:250"' : '') + '><label class="js-synced-message synced-message">' + soy.$$escapeHtml(syncedMessage) + '</label></div>');
};
/**
 * @typedef {{
 *  syncedMessage: ?,
 * }}
 */
yext.locationscan_common.scanResultsTemplates.syncedResults.Params;
if (goog.DEBUG) {
  /** @type {string} */
  yext.locationscan_common.scanResultsTemplates.syncedResults.soyTemplateName = 'yext.locationscan_common.scanResultsTemplates.syncedResults';
}


/**
 * @param {!yext.locationscan_common.scanResultsTemplates.scanDetailsSchemaTemplate.Params} opt_data
 * @param {(?goog.soy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog.soy.data.SanitizedHtml}
 * @suppress {checkTypes}
 */
yext.locationscan_common.scanResultsTemplates.scanDetailsSchemaTemplate = function(opt_data, opt_ijData) {
  opt_ijData = /** @type {!goog.soy.IjData} */ (opt_ijData);
  /** @type {?} */
  const foundSchemaTags = opt_data.foundSchemaTags;
  /** @type {?} */
  const missingSchemaTags = opt_data.missingSchemaTags;
  /** @type {?} */
  const schemaCount = opt_data.schemaCount;
  /** @type {?} */
  const locationName = opt_data.locationName;
  /** @type {?} */
  const locationAddress = opt_data.locationAddress;
  /** @type {?} */
  const locationPhone = opt_data.locationPhone;
  /** @type {?} */
  const aggregateRating = opt_data.aggregateRating;
  /** @type {?} */
  const website = opt_data.website;
  /** @type {?} */
  const hasSummer19Release = opt_data.hasSummer19Release;
  /** @type {?} */
  const numIncorrectFields = opt_data.numIncorrectFields;
  let $output = '<div id="scan-result-details"' + (goog.DEBUG && soy.$$debugSoyTemplateInfo ? ' data-debug-soy="yext.locationscan_common.scanResultsTemplates.scanDetailsSchemaTemplate js/yext/locationscan-common/scanResultsTemplates.soy:266"' : '') + '><div class="scan-details"><section class="publisher-info"><h4>';
  /** @desc  */
  const MSG_UNNAMED = yext.getMsg('Publisher', {}, {html: true});
  $output += MSG_UNNAMED;
  $output += '</h4><div class="partner-icon-container js-partner-icon-container"><img class="partner-icon" src="https://www.yext-static.com/cms/spark/1/site-icon-medium-173.png"></div><div class="partner-details"><p>';
  /** @desc  */
  const MSG_UNNAMED$$1 = yext.getMsg('Website', {}, {html: true});
  $output += MSG_UNNAMED$$1;
  $output += '</p><p>';
  if (foundSchemaTags > 0) {
    $output += '<label class="badge success">';
    /** @desc  */
    const MSG_UNNAMED$$2 = yext.getMsg('Found Schema', {}, {html: true});
    $output += MSG_UNNAMED$$2;
    $output += '</label>';
  }
  if (missingSchemaTags > 0) {
    $output += '<label class="badge error">';
    /** @desc  */
    const MSG_UNNAMED$$3 = yext.getMsg('Missing Schema ({$missingSchemaTags})', {'missingSchemaTags': soy.$$escapeHtml(missingSchemaTags)}, {html: true});
    $output += MSG_UNNAMED$$3;
    $output += '</label>';
  }
  if (numIncorrectFields > 0) {
    $output += '<label class="badge error">';
    /** @desc  */
    const MSG_UNNAMED$$4 = yext.getMsg('Incorrect Data ({$numIncorrectFields})', {'numIncorrectFields': soy.$$escapeHtml(numIncorrectFields)}, {html: true});
    $output += MSG_UNNAMED$$4;
    $output += '</label>';
  }
  $output += '</p><p>' + soy.$$escapeHtml(schemaCount) + '</p></div></section><section class="name js-details-name"><h4>';
  /** @desc  */
  const MSG_UNNAMED$$5 = yext.getMsg('Name', {}, {html: true});
  $output += MSG_UNNAMED$$5;
  $output += '</h4><p>' + soy.$$escapeHtml(locationName) + '</p></section><section class="address js-details-address"><h4>';
  /** @desc  */
  const MSG_UNNAMED$$6 = yext.getMsg('Address', {}, {html: true});
  $output += MSG_UNNAMED$$6;
  $output += '</h4><p>' + soy.$$escapeHtml(locationAddress) + '</p></section><section class="phone js-details-phone"><h4>';
  /** @desc  */
  const MSG_UNNAMED$$7 = yext.getMsg('Phone', {}, {html: true});
  $output += MSG_UNNAMED$$7;
  $output += '</h4><p>' + soy.$$escapeHtml(locationPhone) + '</p></section>';
  if (hasSummer19Release) {
    $output += '<section class="description js-details-description"><h4>';
    /** @desc  */
    const MSG_UNNAMED$$8 = yext.getMsg('Description', {}, {html: true});
    $output += MSG_UNNAMED$$8;
    $output += '</h4></section><section class="hours js-details-hours"><h4>';
    /** @desc  */
    const MSG_UNNAMED$$9 = yext.getMsg('Hours', {}, {html: true});
    $output += MSG_UNNAMED$$9;
    $output += '</h4></section><section class="latLng js-details-latLng"><h4>';
    /** @desc  */
    const MSG_UNNAMED$$10 = yext.getMsg('Lat/Long', {}, {html: true});
    $output += MSG_UNNAMED$$10;
    $output += '</h4></section><section class="images js-details-images"><h4>';
    /** @desc  */
    const MSG_UNNAMED$$11 = yext.getMsg('Images', {}, {html: true});
    $output += MSG_UNNAMED$$11;
    $output += '</h4></section><section class="offer js-details-makes-offer"><h4>';
    /** @desc  */
    const MSG_UNNAMED$$12 = yext.getMsg('Products & Services', {}, {html: true});
    $output += MSG_UNNAMED$$12;
    $output += '</h4></section><section class="reviews js-details-reviews"><h4>';
    /** @desc  */
    const MSG_UNNAMED$$13 = yext.getMsg('Reviews', {}, {html: true});
    $output += MSG_UNNAMED$$13;
    $output += '</h4></section><section class="aggregate-rating js-details-aggregate-rating"><h4>';
    /** @desc  */
    const MSG_UNNAMED$$14 = yext.getMsg('Aggregate Rating', {}, {html: true});
    $output += MSG_UNNAMED$$14;
    $output += '</h4><p>' + soy.$$escapeHtml(aggregateRating) + '</p></section>';
  }
  $output += '<div class="view-listing js-view-listing"><a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri(website)) + '" class="btn btn-secondary">';
  /** @desc  */
  const MSG_UNNAMED$$15 = yext.getMsg('View Website', {}, {html: true});
  $output += MSG_UNNAMED$$15;
  $output += '</a></div></div></div>';
  return soydata.VERY_UNSAFE.ordainSanitizedHtml($output);
};
/**
 * @typedef {{
 *  foundSchemaTags: ?,
 *  missingSchemaTags: ?,
 *  schemaCount: ?,
 *  locationName: ?,
 *  locationAddress: ?,
 *  locationPhone: ?,
 *  aggregateRating: ?,
 *  website: ?,
 *  hasSummer19Release: ?,
 *  numIncorrectFields: ?,
 * }}
 */
yext.locationscan_common.scanResultsTemplates.scanDetailsSchemaTemplate.Params;
if (goog.DEBUG) {
  /** @type {string} */
  yext.locationscan_common.scanResultsTemplates.scanDetailsSchemaTemplate.soyTemplateName = 'yext.locationscan_common.scanResultsTemplates.scanDetailsSchemaTemplate';
}


/**
 * @param {!yext.locationscan_common.scanResultsTemplates.scanDetailsSynced.Params} opt_data
 * @param {(?goog.soy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog.soy.data.SanitizedHtml}
 * @suppress {checkTypes}
 */
yext.locationscan_common.scanResultsTemplates.scanDetailsSynced = function(opt_data, opt_ijData) {
  opt_ijData = /** @type {!goog.soy.IjData} */ (opt_ijData);
  /** @type {?} */
  const powerListingPluralProductName = opt_data.powerListingPluralProductName;
  /** @type {?} */
  const partnerIconSrc = opt_data.partnerIconSrc;
  /** @type {?} */
  const brandName = opt_data.brandName;
  /** @type {?} */
  const errors = opt_data.errors;
  /** @type {?} */
  const warnings = opt_data.warnings;
  let $output = '<div id="scan-result-details"' + (goog.DEBUG && soy.$$debugSoyTemplateInfo ? ' data-debug-soy="yext.locationscan_common.scanResultsTemplates.scanDetailsSynced js/yext/locationscan-common/scanResultsTemplates.soy:344"' : '') + '><div class="scan-details"><section class="publisher-info"><h4>';
  /** @desc  */
  const MSG_UNNAMED = yext.getMsg('Publisher', {}, {html: true});
  $output += MSG_UNNAMED;
  $output += '</h4><div class="partner-icon-container js-partner-icon-container"><img class="partner-icon" src="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeMediaUri(partnerIconSrc)) + '"></div><div class="partner-details"><p>' + soy.$$escapeHtml(brandName) + '</p><p class="js-details-badge"><label class="badge success">';
  if (powerListingPluralProductName) {
    /** @desc  */
    const MSG_UNNAMED$$1 = yext.getMsg('{$powerListingPluralProductName} Synced', {'powerListingPluralProductName': soy.$$escapeHtml(powerListingPluralProductName)}, {html: true});
    $output += MSG_UNNAMED$$1;
  } else {
    /** @desc  */
    const MSG_UNNAMED$$2 = yext.getMsg('Powerlistings Synced', {}, {html: true});
    $output += MSG_UNNAMED$$2;
  }
  $output += '</label>';
  if (soy.$$equals(errors.length, 0) && soy.$$coerceToBoolean(warnings.length) && soy.$$equals(warnings[/** @type {?} */ (0)], 'specialOffer')) {
    $output += '<label class="badge ">';
    /** @desc  */
    const MSG_UNNAMED$$3 = yext.getMsg('No Special Offer', {}, {html: true});
    $output += MSG_UNNAMED$$3;
    $output += '</label>';
  }
  $output += '</p></div></section><div class=\'details-synced\'><span><img src="https://www.yextstatic.com/partner/public/images/powerlistings-synced.svg"><div class=\'synced-text\'>';
  /** @desc  */
  const MSG_UNNAMED$$4 = yext.getMsg('{$xxx} Synced!', {'xxx': soy.$$escapeHtml(powerListingPluralProductName ? powerListingPluralProductName : 'PowerListings')}, {html: true});
  $output += MSG_UNNAMED$$4;
  $output += '</div></span></div></div></div>';
  return soydata.VERY_UNSAFE.ordainSanitizedHtml($output);
};
/**
 * @typedef {{
 *  powerListingPluralProductName: ?,
 *  partnerIconSrc: ?,
 *  brandName: ?,
 *  errors: ?,
 *  warnings: ?,
 * }}
 */
yext.locationscan_common.scanResultsTemplates.scanDetailsSynced.Params;
if (goog.DEBUG) {
  /** @type {string} */
  yext.locationscan_common.scanResultsTemplates.scanDetailsSynced.soyTemplateName = 'yext.locationscan_common.scanResultsTemplates.scanDetailsSynced';
}
