goog.module('yext.plugins.selectbox');
goog.module.declareLegacyNamespace();

// TODO(eefi): After the jQuery 3 migration is complete, uncomment these so that
// this module properly declares its own dependencies.
// require('jquery-ui/ui/keycode');
// require('jquery-ui/ui/position');

const UserAgent = goog.require('yext.plugins.UserAgent');

function setUnselected($button) {
  $button.removeClass('selected-state').find('.btn-selected').removeClass('btn-selected');
}

/**
 * Close any selectbox that is open.
 * There should only be one selectbox open in the DOM at any given time.
 */
function closeSelectbox() {
  setUnselected($('.selectbox'));
  $('.selectbox-options').remove();
  $(document).unbind('.selectbox');
}

/** @suppress {deprecated} */
(function($) {
  // Apparently all browsers have different keycodes for keys, but they seem to
  // be the same for A-Z and 0-9, so just accept these.
  var validSearchKeycode = function(keyCode) {
    return (keyCode >= 48 && keyCode <= 57) // 0-9
           || (keyCode >= 65 && keyCode <= 90) // a-z
           || keyCode == $.ui.keyCode.BACKSPACE;
  };

  var scrollToLi = function($li) {
    var $ul = $li.parent();
    var ulTop = $ul.scrollTop();
    var ulBottom = ulTop + $ul.height();
    var liTop = $li[0].offsetTop;
    var liBottom = liTop + $li.height();
    if (liTop < ulTop) {
      var paddingTop = parseInt($ul.parent().css('padding-top'), 10);
      $ul.scrollTop(liTop - paddingTop);
    } else if (liBottom > ulBottom) {
      var paddingBottom = parseInt($ul.parent().css('padding-bottom'), 10);
      $ul.scrollTop(liBottom - paddingBottom - $ul.height());
    }
  };

  var setSelected = function($button) {
    $button.addClass('selected-state').find('a').addClass('btn-selected');
  };

  /**
   * Check that the selectbox is opened and the options are visible.
   * @param {!jQuery} the div that represents the select
   */
  var isSelected = function($button) {
    $button.hasClass('selected-state') && $button.find('a').hasClass('btn-selected');
  };

  var generateRandomChar = function() {
    var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXY';
    var rand = Math.floor(Math.random() * chars.length);
    return chars.substring(rand, rand + 1);
  };

  var generateUniqueId = function() {
    var string = 'selectbox_' + generateRandomChar() + generateRandomChar() + generateRandomChar();
    while ($('#' + string).length > 0) {
      string += generateRandomChar();
    }
    return string;
  };

  var selectboxToOptions = function($selectbox) {
    return $('#' + $selectbox.attr('data-optionsId'));
  };

  var optionsToSelectbox = function($options) {
    return $('div.selectbox[data-optionsId="' + $options.attr('id') + '"]');
  };

  /**
     * @param {string=} option
     */
  $.fn.selectbox = function(option) {
    var ie7orBelow = window.UserAgent.isIE7OrBelow();

    return this.each(function() {
      // so you can customize the styling of the dropdown button
      var $this = $(this);
      var theme = $this.data('theme');
      var optionsTheme = $this.data('optionstheme');
      var isInDialog = $this.data('dialog-container');
      var label = $this.data('description');

      if (!theme) {
        theme = 'gray';
      }
      // so you can customize the styling of the options box
      if (!optionsTheme) {
        optionsTheme = 'gray';
      }
      if ($(this).hasClass('no-selectbox')) {
        return;
      }
      var nochange = $(this).attr('data-no-change');
      if (option == 'close') {
        var $selectbox = $(this).next('.selectbox');
        if ($selectbox.length > 0) {
          var $options = selectboxToOptions($selectbox);
          if ($options.length > 0) {
            $options.remove();
            $(document).unbind('.selectbox');
          }
        }
        return;
      }
      if ($(this).next('.selectbox').length > 0) {
        if (option == 'update') {
          $(this).next('.selectbox').remove();
        } else {
          return;
        }
      }

      // Create the select box (dropdown button)
      var $selectbox = $('<div>').addClass('selectbox');

      // Add a custom class to the root element of the selectbox
      $selectbox.addClass($(this).data('class'));

      $selectbox.attr('data-optionstheme', optionsTheme);
      $selectbox.attr('tabindex', 0);
      $selectbox.attr('data-optionsId', generateUniqueId());
      var selectButtonIcon = $(this).attr('data-selectbox-icon');
      var $a = $('<a>').addClass('button-' + theme);
      if (selectButtonIcon) {
        $a.addClass('btn-' + selectButtonIcon);
      }

      if (!$(this).data('no-arrow')) {
        $a.addClass('btn-dropdown');
      }

      // readonly-disabled added to have this behavior but not affect
      // any existing :readonly selectboxes, if they exist
      if ($(this).is(':disabled') || $(this).data('readonly-disabled')) {
        $a.addClass('disabled');
      }

      if (label) {
        $a.attr('aria-label', label);
      } else {
        $a.attr('aria-label', 'Select Box');
      }
      $a.attr('role', 'listbox');

      var html = $(this).children('option:selected').html();
      if ($(this).children('option:selected').attr('label')) {
        html = $(this).children('option:selected').attr('label');
      }

      $a.append($('<span>').html(html));
      $selectbox.append($a);
      $selectbox.addClass($(this).attr('class'));
      $(this).after($selectbox);
      $(this).hide();

      // Open selectbox by space, up, or down keys
      $selectbox.keydown(function(e) {
        if (e.keyCode != $.ui.keyCode.TAB) {
          $(this).trigger('click.selectbox');
          $(this).trigger('keydown.selectbox', e.keyCode);
          return false;
        }
        return true;
      });

      // On click, create the selectbox options dropdown
      $selectbox.on('click.selectbox', function() {
        var selectboxId = $(this).attr('data-optionsId');
        var selectOptionsTheme = $(this).attr('data-optionstheme');
        setUnselected($('.selectbox.selected-state'));

        // If any other selectboxes have the same ID, then generate a new ID for
        // them (can happen when cloning an existing selectbox)
        $('div.selectbox[data-optionsId="' + selectboxId + '"]').not($(this)).each(function() {
          $(this).attr('data-optionsId', generateUniqueId());
        });

        var $activeSelectbox = optionsToSelectbox($('.selectbox-options'));

        // Remove any other visible selectbox options
        $('.selectbox-options').remove();
        $(document).unbind('.selectbox');

        // Clicking on an active selectbox should close it and do nothing else
        if ($activeSelectbox.get(0) == this) {
          setUnselected($(this));
          // $(this).removeClass("selected-state").find('.btn-selected').removeClass('btn-selected');;
          return;
        }

        var $prevSelect = $(this).prev('select');
        if ($prevSelect.is(':disabled') || $prevSelect.data('readonly-disabled')) {
          return;
        }

        setSelected($(this));
        // $(this).addClass("selected-state").find('a').addClass('btn-selected');
        $(this).blur();

        var $div = $('<div>').addClass('selectbox-options');

        // styling the dropdown
        if (selectOptionsTheme) {
          $div.addClass('theme-' + selectOptionsTheme);
        }

        $div.attr('id', $(this).attr('data-optionsId'));
        var $arrow = $('<div>').addClass('select-arrow');
        $div.append($arrow);
        var $select = $('<div>').addClass('select');
        var $ul = $('<ul>');
        $select.append($ul);
        var $selectedLi = null;
        $(this).prev('select').children('option').each(function() {
          var $anchor = $('<a/>');
          var hrefLink = $(this).data('url');

          if (hrefLink) {
            $anchor.attr('href', hrefLink);
          }

          var $li = $('<li>')
            .addClass($(this).data('option-class'))
            .append($anchor.html($(this).html()));

          if ($(this).hasClass('selectbox-hide')) {
            // don't show hidden items, but include them to keep index offsets
            $li.hide();
          }
          if ($(this).is(':disabled')) {
            $li.find('a').addClass('disabled');
          }
          if ($(this).is(':selected')) {
            $li.find('a').addClass('hover');
            $selectedLi = $li;
          }
          if ($(this).attr('data-description')) {
            var $description = $('<div>')
              .addClass('option-description')
              .text($(this).attr('data-description'));
            $li.find('a').append($description);
          }
          $ul.append($li);
        });
        $div.append($select);
        var $arrowDown = $('<div>').addClass('select-arrow-down');
        $div.append($arrowDown);

        var specifiedWidth = $(this).prev('select').data('width');
        if (specifiedWidth) {
          $select.css('min-width', specifiedWidth);
        } else {
          $select.css('min-width', ($(this).width() - 2) + 'px');
        }
        if (ie7orBelow) {
          $select.css('width', ($(this).width() - 2) + 'px');
        }

        $('body').append($div);

        $div.position({
          'my': 'left top',
          'at': 'left bottom',
          'of': $(this),
          'collision': 'flip flipfit',
          'offset': '0 3',
        });

        // TODO(kyu): the hidden option is not being set to selected, either fix or remove
        // Bind options click to set hidden <select> option to selected,
        // update selectbox text, and close selectbox options
        $ul.find('a:not(.disabled)').bind('click.selectbox', function() {
          if ($(this).attr('href')) {
            window.location = $(this).attr('href');
          }
          var index = $(this).parent().index();
          var $selectbox = optionsToSelectbox($(this).closest('.selectbox-options'));
          var $select = $selectbox.prev('select');
          var $option = $select.children('option').eq(index);
          if (!nochange) {
            $selectbox.find('span').html($option.attr('label') || $option.html());
          }

          // This click is here to beat the browser security with using js to
          // click on a hidden input[type="file"]
          $option.trigger('click.selectbox');
          $option.prop('selected', true);
          $option.change();

          $('.selectbox-options').remove();
          $selectbox.focus();

          return false;
        });

        var height = 0;
        // $div.height() is 0, so sum height of children
        $div.children().each(function() {
          height += $(this).height();
        });
        var divOffsetTop = $div.offset().top;
        // Hide $div so $(document).height() is correct without potential
        // growth due to visible $div (selectbox options)
        $div.hide();
        var documentHeight;

        // if we are inside of a dialog the document could be longer then the window
        // and the options could fall out of view and not be selectable
        if (isInDialog) {
          documentHeight = $(window).height();
        } else {
          documentHeight = $(document).height();
        }

        var spaceToFit = documentHeight - divOffsetTop - height;
        $div.show();
        if (spaceToFit < 30) {
          // Potentially invert selectbox options (open upward) if there is room
          if ($div.offset().top - height >= 50) {
            $select.addClass('invert');
            $arrow.addClass('invert');
            $arrowDown.addClass('invert');
            $div.position({
              'my': 'center bottom',
              'at': 'center top',
              'of': $(this),
              'collision': 'fit none',
            });
          } else {
            // Not enough room for invert, shrink max-height of options to make
            // it fit without increasing the document height
            var minHeight = 100;
            var maxHeight = documentHeight - divOffsetTop - 40;
            // Want to be multiple of option height to look nice
            var liHeight = $ul.find('li:visible:first').height();
            if (liHeight > 0) {
              maxHeight = Math.floor(maxHeight / liHeight) * liHeight;
              // Always show at least 4 lines
              minHeight = 4 * liHeight;
            }
            $select.find('ul').css('max-height', Math.max(minHeight, maxHeight));
          }
        }

        // For a horizontal collision (e.g., the add location selectbox with a
        // long feed name), keep the arrow still positioned to the center of the
        // selectbox even though the options may be longer
        $arrow.position({
          my: 'center top',
          at: 'center bottom',
          of: optionsToSelectbox($div),
        });
        // Click now to get the equivalent of returning true, but before we
        // bind a click handler that will close the selectbox
        $(document).trigger('click.selectbox');

        // When showing selected, center selected item so it is visible
        if ($selectedLi) {
          var liTop = $selectedLi.position().top;
          var ulHeight = $ul.height();
          $ul.scrollTop(Math.max(0, Math.round(liTop - (ulHeight / 2))));
        }

        var searchString = '';
        var searchTimeoutId = null;

        // Close selectbox options on document click or escape
        $(document).bind('click.selectbox keydown.selectbox', function(e, keyc) {
          // Allow users to send keydown/codes programattically
          if (keyc) {
            e.keyCode = keyc;
          }

          var KEYCODES = [
            $.ui.keyCode.UP, $.ui.keyCode.DOWN,
            $.ui.keyCode.HOME, $.ui.keyCode.END,
            $.ui.keyCode.PAGE_UP, $.ui.keyCode.PAGE_DOWN,
          ];

          if (e.type == 'click' || (e.type == 'keydown' && (e.keyCode == $.ui.keyCode.ESCAPE))) {
            closeSelectbox();
          } else if (e.type == 'keydown' && (e.keyCode == $.ui.keyCode.TAB)) {
            // This will only get hit when the selectbox is selected (open)
            // Need to get the selected selectbox that is open so we can close it
            var $selectbox = $('.selectbox.selected-state');
            // close the selectbox and the refocus since the focus is on nothing
            // after the options are closed
            closeSelectbox();
            $selectbox.focus();
          } else if (e.type == 'keydown' && $.inArray(e.keyCode, KEYCODES) >= 0) {
            var $a = $('.selectbox-options a.hover');
            if ($a.length === 0) {
              $('.selectbox-options a:not(.disabled):visible:first').addClass('hover');
            } else {
              var $li = $a.parent();

              while ($li.length > 0) {
                if (e.keyCode == $.ui.keyCode.UP) {
                  $li = $li.prev();
                } else if (e.keyCode == $.ui.keyCode.DOWN) {
                  $li = $li.next();
                } else if ($.inArray(
                  e.keyCode, [$.ui.keyCode.HOME, $.ui.keyCode.PAGE_UP]) >= 0) {
                  $li = $li.parent().children('li').first();
                } else if ($.inArray(
                  e.keyCode, [$.ui.keyCode.END, $.ui.keyCode.PAGE_DOWN]) >= 0) {
                  $li = $li.parent().children('li').last();
                }

                if (!$li.is(':visible')) {
                  continue;
                }
                if ($li.length > 0 && !$li.find('a:visible').hasClass('disabled')) {
                  $li.siblings().find('a.hover').removeClass('hover');
                  $li.find('a').addClass('hover');
                  scrollToLi($li);

                  break;
                }
              }
            }
          } else if (e.type == 'keydown' && $.inArray(e.keyCode, [$.ui.keyCode.ENTER, $.ui.keyCode.SPACE]) >= 0) {
            $('.selectbox-options a.hover').trigger('click.selectbox');
          } else if (e.type == 'keydown' && validSearchKeycode(e.keyCode)) {
            if (e.keyCode == $.ui.keyCode.BACKSPACE) {
              if (searchString.length > 0) {
                searchString = searchString.slice(0, searchString.length - 1);
              }
            } else {
              var c = String.fromCharCode(e.keyCode);
              if (c && c.length > 0) {
                // Append to the search string
                searchString += String.fromCharCode(e.keyCode).toLowerCase();
              } else {
                return false;
              }
            }

            // Clear search timeout
            if (searchTimeoutId) {
              clearTimeout(searchTimeoutId);
            }

            // Perform search through all options, finding the option with the
            // lowest (>= 0) indexOf score, breaking ties using higher up
            // options in the list
            var $ul = $('.selectbox-options ul');
            var $aToHover = null;
            var bestIndex = $ul.find('a').length + 1;
            $ul.children('li').each(function() {
              var $a = $(this).children('a');
              var text = $a.text().toLowerCase().replace(/[^a-z0-9]/g, '');
              var indexOf = text.indexOf(searchString);
              if (indexOf >= 0 && indexOf < bestIndex) {
                bestIndex = indexOf;
                $aToHover = $a;
              }
            });

            if ($aToHover) {
              $ul.find('a.hover').removeClass('hover');
              $aToHover.addClass('hover');
              scrollToLi($aToHover.closest('li'));
            }

            // Set new search timeout
            searchTimeoutId = setTimeout(function() {
              searchString = '';
            }, 1000);
          } else {
            // Not a handled action, so allow it to bubble (e.g., CMD+R / F5)
            return;
          }
          return false;
        });

        // Set z-index of selectbox to the z-index of the first parent with a non-auto z-index
        var zindexes = $(this).parents().map(function() {
          return $(this).css('z-index');
        });
        zindexes.push($(this).css('z-index'));
        for (var i = 0; i < zindexes.length; i++) {
          if (zindexes[i] != 'auto' && zindexes[i] != 0) {
            $div.css('z-index', zindexes[i]);
            break;
          }
        }
        return false;
      });

      $(this).bind('change selectedOption disabled', function() {
        var $option = $(this).children('option:selected');
        var $selectbox = $(this).next('.selectbox');
        if (!nochange) {
          $selectbox.find('span').html($option.attr('label') || $option.html());
        }

        var $a = $selectbox.find('a');
        $a.toggleClass('disabled', $(this).is(':disabled'));
      });
    });
  };

  $.fn['selectbox'] = $.fn.selectbox;
})(jQuery);

exports = {
  setUnselected,
  closeSelectbox,
};
