/**
 * Yext Core Helpers
 * @author Billy Bastardi (billy@yext.com)
 *
 * Global namespace that will contain all of our global helpers
 * and act as the root context for all of our sub models
 */
goog.provide('yext');

/** @type {number} */
yext.userId = window['userId'];

/** @type {number} */
yext.businessId = window['businessId'];

/** @type {!Array<number>} */
yext.betaProductFeatures = window['betaProductFeatures'] || [];

yext.employeeId = window['employeeId'];

/**
 * @param {number} userId the apps user id
 * @param {number} businessId the context business id
 * @param {!Object} additionalInitData Additional params to use in init
 */
yext.init = function(userId, businessId, additionalInitData) {
  additionalInitData = additionalInitData || {};

  yext.userId = userId;
  yext.businessId = businessId;
  yext.betaProductFeatures = additionalInitData['betaProductFeatures'] || [];
  yext.employeeId = additionalInitData['employeeId'];
};
goog.exportSymbol('yext.init', yext.init);

/**
  * Regular expression to support all valid URLS except FTP protocol
  * http://mathiasbynens.be/demo/url-regex
  * version: diegoperini
  */
yext.CONTAINS_URL_REGEX = /(?:(?:https?):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]+-?_?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?/i;

// version: stephenhay, used for speedier evaluation
yext.CONTAINS_SIMPLE_URL_REGEX = /(https?):\/\/[^\s\/$.?#].[^\s]*/i;

// version: same as the regex in StringUtilities.java
yext.EMAIL_REGEX = /^[\w\.\-\+\_]+@[A-Za-z0-9\.\-]+\.[a-zA-Z]+$/;
yext.URL_REGEX = new RegExp('^'+yext.CONTAINS_URL_REGEX.source+'$', 'i');
goog.exportSymbol('yext.URL_REGEX', yext.URL_REGEX);

yext.JSON = JSON;
goog.exportSymbol('yext.JSON', yext.JSON);

/**
 * Provide a namespace for a particular module if it doesn't already exist
 * to enable modules to be augemented much easier
 * @param {string} namespaceString A string representation of the namespace
 */
yext.provide = function(namespaceString) {
  var modules = namespaceString.split('.');
  var namespace = window;

  for (var i = 0; i < modules.length; i++) {
    namespace[modules[i]] = namespace[modules[i]] || {};
    namespace = namespace[modules[i]];
  }
};
goog.exportSymbol('yext.provide', yext.provide);

/**
 * Enhanced logging functionality that will log information
 * and avoid browsers that don't support console (e.g. ie)
 * @param {string} msg The message to log
 * @param {boolean=} trace Display the function that called logging
 * @param {boolean=} warning Display this message as a warning or a subtle message
 */
yext.log = function(msg, trace, warning) {
  trace = trace || false,
  warning = warning || false;

  var caller = yext.log.caller ? yext.log.caller.name : 'anonymous';

  // Avoid browsers that don't support logging
  if (typeof window.console === 'object'
      && typeof window.console.log === 'function') {
    msg = trace ? caller + ': ' + msg : msg;
    warning ? window.console.warn(msg) : window.console.log(msg);
  }
};

/**
 * Forward warnings to wab.log function
 * @param {string} msg the message to log
 */
yext.warn = function(msg, trace) {
  yext.log(msg, trace, true);
};

/**
 * Is a given object of type 'boolean'?
 * @param {boolean} b Object to compare
 * @return {boolean} true if the object is a boolean
 */
yext.isBoolean = function(b) {
  return typeof b === 'boolean';
};

/**
 * @deprecated Use `typeof f === 'function'`
 * Is a given object of type 'function'?
 * @param {!Object} f Object to compare
 * @return {boolean} true if the object is a function
 */
yext.isFunction = function(f) {
  return typeof f === 'function';
};
goog.exportSymbol('yext.isFunction', yext.isFunction);

/**
 * @deprecated Use `typeof n === 'number'`
 * Is a given object of the type 'number'?
 * @param {number} n Object to compare
 * @return {boolean} true if the object is a number
 */
yext.isNumber = function(n) {
  return typeof n === 'number';
};
goog.exportSymbol('yext.isNumber', yext.isNumber);

/**
 * @deprecated Use `typeof s === 'string'`
 * Is a given object of the type 'string'?
 * @param {string} s Object to compare
 * @return {boolean} true if the object is a string
 */
yext.isString = function(s) {
  return typeof s === 'string';
};

/**
 * @deprecated Use goog.isObject
 * Check whether the type of an object is a raw 'object'
 * @param {!Object} o Object to compare
 * @return {boolean} true if the object is an object
 */
yext.isObject = function(o) {
  return typeof o === 'object' && !!o;
};
goog.exportSymbol('yext.isObject', yext.isObject);

/**
 * @deprecated Use obj === null
 * Is the given object actually 'null'?
 * @param {!Object} obj Object to compare
 * @return {boolean} true if the object is null
 */
yext.isNull = function(obj) {
  return obj === null;
};

/**
 * @deprecated Use instanceof directly
 * Is the given object of type `type`?
 * @param {Class} type the constructor of the type to test against
 * @param {!Object} obj the object to test against
 */
yext.instanceOf = function(type, obj) {
  return obj instanceof type;
};

/**
 * Is the given object actually an email?
 * @param {string} email Email to check
 * @return {boolean} true if the string is an email
 */
yext.isValidEmail = function(email) {
  return !!email && yext.isString(email) && yext.EMAIL_REGEX.test(email);
};

/**
 * Convert color from hex format into decimal format in an array.
 * @param {string} color The hex format (e.g. ffffff).
 * @return {array.<number>} A list of the RGB values (e.g. [255, 255, 255]).
 */
yext.getRgbFromHex = function(color) {
  if (color.substring(0, 1) == '#') {
    color = color.substring(1);
  }
  var rgb = parseInt(color, 16); // convert rrggbb to decimal
  var r = (rgb >> 16) & 0xff; // extract red
  var g = (rgb >> 8) & 0xff; // extract green
  var b = (rgb >> 0) & 0xff; // extract blue
  return [r, g, b];
};
goog.exportSymbol('yext.getRgbFromHex', yext.getRgbFromHex);

/**
 * Rotate specified element based on the specified degree
 * @param {!jQuery} $ele the element to rotate
 * @param {number} degree angle to orient the advanced button
 */
yext.rotateElement = function($ele, degree) {
  var transform = 'rotate(' + degree + 'deg)';
  $ele.css({
    '-webkit-transform': transform,
    '-moz-transform': transform,
    '-ms-transform': transform,
    '-o-transform': transform,
    'transform': transform,
    '-webkit-transition': '0.2s',
    '-transition': '0.2s',
  });
};
goog.exportSymbol('yext.rotateElement', yext.rotateElement);

/**
 * Returns the total number of unique properties for a particular object
 * NOTE: Does not work recursively (only counts the first layer of properties)
 * @param {!Object} obj The object to get the size of
 * @returns {number} the size of the object
 */
yext.size = function(obj) {
  var size = 0;
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      size ++;
    }
  }

  return size;
};
goog.exportSymbol('yext.size', yext.size);

/**
 * Update the list with the passed in selector to select the right option
 * @param {string} selector The css selector for the list
 * @param {string} value The option value to make 'selected'
 */
yext.selectListOption = function(selector, value) {
  var optionValue;

  if (value === null) {
    optionValue = 'null';
  } else {
    optionValue = value || '';
  }

  $(selector + ' option[value="' + optionValue + '"]')
    .prop('selected', true)
    .trigger('selectedOption');
};
goog.exportSymbol('yext.selectListOption', yext.selectListOption);

/**
 * Retrieve the value of a param from the url if present, otherwise
 * return null.
 * @param {string} name The param to search for in the url
 * @param {string=} url URL to use instead of the current window's
 */
yext.getUrlParam = function(name, url) {
  url = url || window.location.href;
  var results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(url);

  if (results == null) {
    return null;
  } else {
    return results[1] || null;
  }
};
goog.exportSymbol('yext.getUrlParam', yext.getUrlParam);

/**
 * Set the caret position on an input element.
 * Optionally select/highlight a chunk of text if you pass in a distance
 *
 * @param {!jQuery} $el Reference of the input to position the cursor on
 * @param {number} pos The index of where to position the cursor
 * @param {number=} opt_dis Optional distance to select, starting at the pos
 */
yext.setCaretPosition = function($el, pos, opt_dis) {
  var htmlEl = $el.get(0); // Reference the HTMLObject

  opt_dis = opt_dis || 0;

  // The native HTMLObject gives us access to the Range and Selection API
  if (htmlEl) {
    // IE Support for cursor position
    if (htmlEl.createTextRange) {
      // Timeout required to focus properly
      setTimeout(function() {
        var range = htmlEl.createTextRange();
        range.move('character', pos);
        range.moveEnd('character', opt_dis);
        range.select();
        htmlEl.focus();
      }, 0);
    } else {
      // Chrome, Safari, Firefox support
      if (htmlEl.setSelectionRange) {
        htmlEl.setSelectionRange(pos, pos + opt_dis);

        // Timeout required to focus properly
        setTimeout(function() {
          $el.focus();
        }, 0);
      }
    }
  }
};
goog.exportSymbol('yext.setCaretPosition', yext.setCaretPosition);

/**
 * Get the caret position of a particular input
 * NOTE: The input most be focused to get the proper value
 * @param {!jQuery} $el Reference of the input box
 * @return {number} the current index of the caret
 */
yext.getCaretPosition = function($el) {
  var htmlEl = $el.get(0);

  // Modern Browser Support
  if (typeof htmlEl.selectionEnd !== 'undefined') {
    return htmlEl.selectionEnd;
  } else if (htmlEl.createTextRange) { // IE Support
    var range = document.selection.createRange();

    var len = htmlEl.value.length;

    // Create a working TextRange that lives only in the input
    var textInputRange = htmlEl.createTextRange();
    var textInputRangeCopy = textInputRange.duplicate();

    textInputRange.moveToBookmark(range.getBookmark());

    textInputRangeCopy.setEndPoint('EndToStart', textInputRange);

    return textInputRangeCopy.text.length;
  }

  // If there is no selection, we assume the selection is at the 0 index
  return 0;
};
goog.exportSymbol('yext.getCaretPosition', yext.getCaretPosition);

/**
 * Scrolls to a particular element on the page
 * @param {!jQuery} $el The element to scroll too
 * @param {number} [opt_speed=1000] The time it takes to scroll from current to next
 * @param {number} [opt_topOffset=0] Add an additional offset from the element we're scrolling too
 */
yext.scrollTo = function($el, opt_speed, opt_topOffset) {
  if (typeof $el === 'undefined') {
    return;
  }

  // Set default values
  opt_speed = opt_speed || 1000;
  opt_topOffset = opt_topOffset || 0;

  $('html, body').animate({
    scrollTop: $el.offset().top - opt_topOffset,
  }, opt_speed);
};
goog.exportSymbol('yext.scrollTo', yext.scrollTo);

/**
 * Takes a string and check's to see if it's a valid URL
 * @param {string} url The url string to validate
 * @return {boolean} true if it's valid
 */
yext.isValidUrl = function(url) {
  try {
    return Boolean(new URL(url));
  } catch (e) {
    return false;
  }
};

/**
 * Check if an image is loaded
 *
 * @param {HTMLElement} img Image object
 */
yext.imageLoaded = function(img) {
  if (!img.complete) {
    return false;
  }

  if (typeof img.naturalWidth !== 'undefined' && img.naturalWidth === 0) {
    return false;
  }

  return true;
};

/**
 * Sizes an image to fit inside a container of width and height by finding
 * the limiting dimension and adding negative margins
 *
 * @param {Image} img Image object to be sized
 * @param {number} width Target width
 * @param {number} height Target height
 */
yext.sizeImage = function(img, width, height) {
  var targetWidthToHeight = width/height;
  var widthToHeight = img.width/img.height;

  if (targetWidthToHeight < widthToHeight) {
    var margin = Math.round(((height/img.height)*img.width - width)/2);
    img.height = height;
    img.style.width = 'auto';
    img.style.margin = '0 -'+margin+'px';
  } else {
    var margin = Math.round(((width/img.width)*img.height - height)/2);
    img.width = width;
    img.style.height = 'auto';
    img.style.margin = '-'+margin+'px 0';
  }
};

/**
 * Ported from PhotoUtil.java this method takes a photo url and generates
 * a dynamic url that will provide a smaller version. This drops the
 * protocol to keep things simple
 *
 * @param {string} photoUrl The url to make dynamic
 * @param {number} width Target width
 * @param {number} height Target height
 */
yext.getDynamicThumbnailUrl = function(photoUrl, width, height) {
  let subdomainIndex;
  if (photoUrl.indexOf('.mktgcdn.com/p') != -1) {
    subdomainIndex = photoUrl.indexOf('.');
    var lastSlashIndex = photoUrl.lastIndexOf('/');
    var ext = photoUrl.match(/\.\w+$/i);

    photoUrl =
      '//dynl' + photoUrl.substring(subdomainIndex, lastSlashIndex + 1) +
      width + 'x' + height +
      (ext ? ext[0] : '');
  } else if (photoUrl.indexOf('mktgcdn.com/f') != -1) {
    subdomainIndex = photoUrl.indexOf('.');
    photoUrl = 'https://dyn' + photoUrl.substring(subdomainIndex) + 'width=' + width +
      ',height=' + height + ',fit=pad';
  }

  return photoUrl;
};

/**
 * Converters for dealing with iframe transport for IE
 */
yext.fileuploadIframeConverters = {
  'html iframejson': function(htmlEncodedJson) {
    return $.parseJSON($('<div/>').html(htmlEncodedJson).text());
  },
  'iframe iframejson': function(iframe) {
    return $.parseJSON(iframe.find('body').text());
  },
};

/**
 * Uppercase first letter of a string
 */
yext.ucfirst = function(str) {
  return str.toUpperCase()[0] + str.toLowerCase().substring(1);
};

/**
 * Config for the gumi dropdown plugin
 */
yext.gumiConfig = {
  'buttonClass': 'btn-default',
  'buttonSelected': 'btn-selected',
  'optionDisabledClass': 'option-disabled',
  'dropdownClass': 'no-selectbox options-dropdown',
  /** @this {HTMLElement} */
  'onOpen': function() {
    var $self = $(this);
    var $btn = $self.find('.gumi-btn');
    var $dropdown = $self.find('.gumi-dropdown');

    $dropdown.position({
      my: 'left top',
      at: 'left bottom',
      of: $btn,
      collision: 'flip',
      offset: '0 3px',
    });
  },
};

/**
 * Return an object that contains properties for each parameter passed in via URL.
 * NOTE: No decoding is done by this function. If decoding is needed, be sure to do so to the values
 * of the returned object.
 * For URL Hash params, see ui/lib/url.js.
 *
 * @return {?Object.<string>} empty if no params; object with key=value&20pair for each param
 */
yext.getUrlParams = function() {
  var paramsObj = {};
  var paramsStr = window.location.search.substring(1);

  if (paramsStr.length > 0) {
    var paramsArr = paramsStr.split('&');

    for (var i = 0; i < paramsArr.length; i++) {
      var param = paramsArr[i].split('=');
      if (paramsObj.hasOwnProperty(param[0])) {
        if (!Array.isArray(paramsObj[param[0]])) {
          paramsObj[param[0]] = [paramsObj[param[0]]];
        }
        paramsObj[param[0]].push(param[1]);
      } else {
        paramsObj[param[0]] = param[1];
      }
    }
  }
  return paramsObj;
};

/**
 * Return a string from a paramsObject (map) that represents the new url
 * NOTE: No encoding is done here, and this function assumes the values of paramsObj
 * are already encoded to be put into the url. See serializeUrl for encoding
 *
 * @param {string} url The base URL upon which to append parameters
 * @param {!Object} params Object with key value pair for each parameter (already encoded)
 *
 * @return {string} Base URL with appended parameters (if any)
 */
yext.buildUrlWithParams = function(url, params) {
  if (!yext.isString(url)) {
    throw new TypeError('Invalid URL; must be of type `string`');
  }

  if (!yext.isObject(params)) {
    throw new TypeError('Could not build params; must be of type `object`');
  } else if (yext.size(params) <= 0) {
    return url;
  }

  var assembledParams = [];
  for (var key in params) {
    if (Array.isArray(params[key])) {
      params[key].forEach(function(subVal) {
        assembledParams.push(key + '=' + subVal);
      });
    } else {
      assembledParams.push(key + '=' + params[key]);
    }
  }

  return url + '?' + assembledParams.join('&');
};

/**
 * Takes a base URL and appends to it a serialized set of parameters
 * NOTE: See buildUrlParams if no encoding is needed (especially if getUrlParams() was used to
 * get the params).
 *
 * @param {string} url The base URL upon which to append parameters
 * @param {!Object} params Object with key value pair for each parameter (not yet encoded)
 *
 * @return {string} Base URL with appended, encoded parameters (if any)
 */
yext.serializeUrl = function(url, params) {
  if (!yext.isString(url)) {
    throw new TypeError('Invalid URL; must be of type `string`');
  }

  if (!yext.isObject(params)) {
    throw new TypeError('Could not serialize params; must be of type `object`');
  } else if (yext.size(params <= 0)) {
    return url;
  }

  var serializedParams = [];

  for (var key in params) {
    var val = params[key];

    if (yext.isObject(val)) {
      val = yext.JSON.stringify(val);
    }

    serializedParams.push(key + '=' + window.encodeURI(val));
  }

  return url + '?' + serializedParams.join('&');
};

/**
 * Returns the key for the given value in the given enum
 *
 * @param {!Object} enumToCheck An enum
 * @param {T} value One of the enum values
 */
yext.enumToString = function(enumToCheck, value) {
  if (!yext.isObject(enumToCheck)) {
    throw new TypeError('enumToString: Invalid enumToCheck; must be of type `Object`');
  }
  for (var key in enumToCheck) {
    if (enumToCheck.hasOwnProperty(key) && enumToCheck[key] == value) {
      return key;
    }
  }
  return null;
};
goog.provide('yext.i18n');

goog.require('yext');
goog.require('yext.i18n.languagePluralizers');
goog.require('goog.i18n.MessageFormat');

/**
 * Internationalization methods
 */
goog.scope(function() {
  /**
   * Get the translated plural form of the message with the given id and
   * version index and format it
   * @param {string} ctxt the context of the message to translate
   * @param {string} msgId the id of the message to translate
   * @param {number} index the version of the message to use
   * @param {string} defaultMsg the default value for the message
   * @param {...*} var_args arguments to format the message with
   *  or a single object mapping message parameters to values
   * @return {string} The translated message
   */
  var getFormattedMessage = function(ctxt, msgId, index, defaultMsg, var_args) {
    var msg;
    if (window.pseudoLocalizationParams) {
      msg = pseudoLocalize(defaultMsg);
    } else {
      var versions = null;
      if (ctxt != null && window.contextMap) {
        var m = window.contextMap[ctxt];
        if (m) {
          versions = m[msgId];
        }
      } else if (ctxt == null && window.translationsMap) {
        versions = window.translationsMap[msgId];
      }
      msg = (!versions || index >= versions.length) ? defaultMsg : versions[index];
    }

    var args = Array.prototype.slice.call(arguments, 4);

    // soy messages are stored with named parameters while js ones are numbered
    if (args.length == 1 && typeof args[0] == 'object') {
      // if there's only one arg and it's an object
      // then we're using a parameterObject of a parameterArray
      var parameterObject = args[0];
      // weirdly non-plural messages prefix their arguments with a $ but plural messages don't
      return msg.replace(/{\$?([^}]+)}/g, function(match, key) {
        return typeof parameterObject[key] != 'undefined'
          ? parameterObject[key]
          : match
        ;
      });
    } else {
      // Format the message
      return msg.replace(/{(\d+)}/g, function(match, number) {
        return typeof args[number] != 'undefined'
          ? args[number]
          : match
        ;
      });
    }
  };

  /**
   * Pseudolocalize the given msg given the current window.pseudoLocalizationParams
   * @param {string} msg the context of the message to pseudolocalize
   * @return {string} The pseudolocalized message
   */
  var pseudoLocalize = function(msg) {
    // TODO(sgutz) Inject bracket symbols and substitution alphabet from controller?
    if (!window.pseudoLocalizationParams) {
      return msg;
    }

    var result = '';
    if (window.pseudoLocalizationParams['bracket']) {
      result += '||';
    }

    var paramRegex = /\{\d\}/;
    // Note(sgutz): This will break if '>' appears inside a string literal in the tag
    var htmlTagRegex = /<[^>]*>/;
    var htmlEntityRegex = /&#\d{1,3};|&[A-Za-z]+;/;
    var ignoreRegexString = [paramRegex.source, htmlTagRegex.source, htmlEntityRegex.source].join('|');

    var charCount = 0.0;
    var lengthenPercent = getPseudolocalizationLengthenPercent(msg.replace(ignoreRegexString, '').length);

    // The grouping preserves the delimiter
    var parts = msg.split(new RegExp('(' + ignoreRegexString + ')'));
    for (var j = 0; j < parts.length; j++) {
      var part = parts[j];
      // Leave message format parameters and HTML tags/entities
      if (part.match(new RegExp(ignoreRegexString))) {
        result += part;
        continue;
      }

      for (var i = 0; i < part.length; i++) {
        var s = String.fromCharCode(getPseudolocalizationSubstitution(part.charCodeAt(i)));
        result += s;
        if (lengthenPercent > 0) {
          charCount += 1;
          while (charCount > 100.0 / lengthenPercent) {
            result += s;
            charCount -= 100.0 / lengthenPercent;
          }
        }
      }
    }

    if (window.pseudoLocalizationParams['bracket']) {
      result += '||';
    }

    return result;
  };

  /**
   * Determine the amount to lengthen a PseudoLocalized string
   * @param {int} msgLength the length of the message to lengthen
   * @return {int} The percent to lengthen the string
   */
  var getPseudolocalizationLengthenPercent = function(msgLength) {
    if (!window.pseudoLocalizationParams['lengthen']) {
      return 0;
    } else if (msgLength <= 4) {
      return 100;
    } else if (msgLength <= 10) {
      return 80;
    } else if (msgLength <= 20) {
      return 60;
    } else if (msgLength <= 30) {
      return 40;
    } else if (msgLength <= 50) {
      return 20;
    } else {
      return 10;
    }
  };

  /**
   * Substitute an alternate character for c as indicated by
   * window.pseudoLocalizationParams
   * @param {int} c the character to substitute for
   * @return {int} The substituted character
   */
  var getPseudolocalizationSubstitution = function(c) {
    if (!window.pseudoLocalizationParams['substitute'] || !window.pseudoLocalizationParams['upper']
        || !window.pseudoLocalizationParams['lower']) {
      return c;
    }

    if (c >= 'A'.charCodeAt(0) && c <= 'Z'.charCodeAt(0)) {
      return window.pseudoLocalizationParams['upper'].charCodeAt(c - 'A'.charCodeAt(0));
    }
    if (c >= 'a'.charCodeAt(0) && c <= 'z'.charCodeAt(0)) {
      return window.pseudoLocalizationParams['lower'].charCodeAt(c - 'a'.charCodeAt(0));
    }
    return c;
  };

  /**
   * Get the translated form of the message with the given id.
   * @param {string} msgId the id of the message to translate
   * @param {...*} var_args arguments to format the message with
   * @return {string} The translated message
   */
  yext.msg = function(msgId, var_args) {
    // Copy the arguments array
    var args = Array.prototype.slice.call(arguments, 0);
    return yext.msgc.apply(null, [null].concat(args));
  };
  goog.exportSymbol('yext.msg', yext.msg);

  /**
   * Get the translated form of the message with the given context and id.
   * @param {string} ctxt the context of the message to translate
   * @param {string} msgId the id of the message to translate
   * @param {...*} var_args arguments to format the message with
   * @return {string} The translated message
   */
  yext.msgc = function(ctxt, msgId, var_args) {
    var args = Array.prototype.slice.call(arguments, 2);
    return getFormattedMessage.apply(null, [ctxt, msgId, 0, msgId].concat(args));
  };
  goog.exportSymbol('yext.msgc', yext.msgc);

  /**
   * Get the translated plural form of the message with the given ids and
   * variable number.
   * @param {string} msgId the id of the message to translate
   * @param {string} pluralMsgId the plural id of the message to translate
   * @param {number} n the number to use in pluralization
   * @param {...*} var_args arguments to format the message with
   *  or a single object mapping message parameters to values
   * @return {string} The translated message
   */
  yext.msgn = function(msgId, pluralMsgId, n, var_args) {
    // Copy the arguments array
    var args = Array.prototype.slice.call(arguments, 0);
    return yext.msgcn.apply(null, [null].concat(args));
  };
  goog.exportSymbol('yext.msgn', yext.msgn);

  /**
   * Get the translated plural form of the message with the given ids and
   * variable number.
   * @param {string} ctxt the context of the message to translate
   * @param {string} msgId the id of the message to translate
   * @param {string} pluralMsgId the plural id of the message to translate
   * @param {number} n the number to use in pluralization
   * @param {...*} var_args arguments to format the message with
   *  or a single object mapping message parameters to values
   * @return {string} The translated message
   */
  yext.msgcn = function(ctxt, msgId, pluralMsgId, n, var_args) {
    const currentLanguage = window.currentLanguage || 'en';
    const pluralKeywordToIndex = window.pluralKeywordToIndex || {one: 0, other: 1};
    var defaultMsg = n == 1 ? msgId : pluralMsgId;
    var pluralizer = yext.i18n.languagePluralizers[currentLanguage];
    var index = pluralKeywordToIndex[pluralizer(n)];
    if (index === undefined) {
      // TODO(sgutz) Log an error
      index = 0;
    }

    // if we are using a parameters object (soy) we don't need n
    if (var_args && typeof var_args == 'object') {
      var args = [var_args];
    } else {
      // Keep n as an argument
      var args = Array.prototype.slice.call(arguments, 3);
    }
    return getFormattedMessage.apply(null, [ctxt, msgId, index, defaultMsg].concat(args));
  };
  goog.exportSymbol('yext.msgcn', yext.msgcn);

  // for soy templates rendered from js, we want to convert the goog.getMsg calls into yext.msgc
  goog.getMsg = yext.getMsg = function(msgId, parametersObject) {
    // SoyExtractor.java makes messages like:
    // "Your Share of Intelligent Search is {START_STRONG}{TOTAL_FORMATTED}%{END_STRONG}"
    // but closure compiler tries to use messages messages like:
    // "Your Share of Intelligent Search is {$startStrong}{$totalFormatted}%{$endStrong}"

    var snakeMsgId = msgId.replace(/{(\$[^}]+)}/g, function(match, group) {
      return '{' + camelToSnake(group.substring(1)) + '}';
    });

    // translate all the keys in the parametersObject to snake case
    if (parametersObject) {
      var snakeParametersObject = {};
      Object.keys(parametersObject).forEach(function(key) {
        snakeParametersObject[camelToSnake(key)] = parametersObject[key];
      });
    }

    // TODO: Figure out how to handle context? The closure soyToJsCompiler ignores context.
    return yext.msgc.apply(null, [null, snakeMsgId, snakeParametersObject]);
  };

  // totalFormatted -> TOTAL_FORMATTED
  // TotalFormatted -> TOTAL_FORMATTED
  // TOTAL_FORMATTED -> TOTAL_FORMATTED
  var camelToSnake = function(camel) {
    return camel
      .replace(/[A-Z]+/g, ' $&').trim()
      .replace(/(\s|_)+/g, '_')
      .toUpperCase();
  };

  // plural messages look like: {1,plural,\x3d1{{1} location}other{{1} locations}}
  // this matches the first two sets of matched braces, only 2 levels of nesting is supported
  // the group with index 1 will be "{1} location"
  // the group with index 2 will be "{1} locations"
  var matchBraces = /{[^{}]*{((?:[^{}]*(?:{[^{}]*})*[^{}]*)*)}*}[^{}]*{((?:[^{}]*(?:{[^{}]*})*[^{}]*)*)}*}/;

  // this is used as a constructor so instead of returning we set the result on this/self
  /** @this {!Object} */
  goog.i18n.MessageFormat = function(msgString) {
    var self = this;
    var msgIdMatcher = msgString.match(matchBraces);
    if (msgIdMatcher && msgIdMatcher.length > 1) {
      // matching braces will extract the first case which is also the msgId
      // "{1} location" in the above example
      var msgId = msgIdMatcher[1];
      var pluralMsgId = msgIdMatcher[2] || msgId;

      // soy expects this method to return an object with a formatIgnoringPound method
      // it then calls that method with the parameters object
      self.formatIgnoringPound = function(parametersObject) {
        // the first property is the plural variable
        for (var key in parametersObject) {
          var n = parametersObject[key];
          break;
        }

        // soy messages are stored with named parameters while js ones are numbered
        return yext.msgn.apply(null, [msgId, pluralMsgId, n, parametersObject]);
      };
      return;
    }
    // if regexing fails then just make a function that returns the original message
    self.formatIgnoringPound = function() {
      return msgString;
    };
  };

  /**
   * Format a list to be separated by commas
   *
   * @param {!Array<string>} List of strings to be formatted
   * @return {string} The formatted list
   */
  yext.formatCommaList = (function() {
    // TRANSLATORS: The separator used to delimit items in a list. If this
    // is not sufficient for a comma delimited list in the language (ie. an
    // initial or terminal string is necessary) you are translating, please
    // let us know.
    // EXAMPLE: 1, 2, 3, 4
    var separator = yext.msgc('list', ', ');
    return function(args) {
      return args.join(separator);
    };
  })();

  yext.getEmbeddedFieldTranslations = function() {
    return {
      'NAME': yext.msg('NAME'),
      'ADDRESS': yext.msg('ADDRESS'),
      'ADDRESS2': yext.msg('ADDRESS2'),
      'DISPLAY ADDRESS': yext.msg('DISPLAY ADDRESS'),
      'CITY': yext.msg('CITY'),
      'STATE': yext.msg('STATE'),
      'COUNTRY': yext.msg('COUNTRY'),
      'ZIP': yext.msgc('postal-code', 'ZIP'),
      'PHONE': yext.msg('PHONE'),
      'WEBSITE': yext.msg('WEBSITE'),
      'WEBSITE URL': yext.msg('WEBSITE URL'),
      'WEBSITE DISPLAY URL': yext.msg('WEBSITE DISPLAY URL'),
      'STORE ID': yext.msg('STORE ID'),
      'LOCATION ID': yext.msg('LOCATION ID'),
      'CERTIFICATIONS': yext.msg('CERTIFICATIONS'),
      'INSURANCE ACCEPTED': yext.msg('INSURANCE ACCEPTED'),
      'CONDITIONS TREATED': yext.msg('CONDITIONS TREATED'),
      'ADMITTING HOSPITALS': yext.msg('ADMITTING HOSPITALS'),
      'SERVICES': yext.msg('SERVICES'),
      'FIRST NAME': yext.msg('FIRST NAME'),
      'MIDDLE NAME': yext.msg('MIDDLE NAME'),
      'LAST NAME': yext.msg('LAST NAME'),
      'OFFICE NAME': yext.msg('OFFICE NAME'),
    };
  };
  /**
   * Compare two strings considering locale and disregarding case
   * @param {string} s0 the first string to compare
   * @param {string} s1 the second string to compare
   * @return {number} The result of the comparison
   */
  yext.compareIgnoreCase = function(s0, s1) {
    s0 = s0.toLocaleLowerCase();
    s1 = s1.toLocaleLowerCase();
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
    try {
      return s0.localeCompare(s1, window.currentLanguage);
    } catch (e) {
      if (e.name === 'RangeError') {
        return (s0 > s1) ? 1 : -1;
      }
      throw e;
    }
  };
});
