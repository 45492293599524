goog.module('yext.plugins.UserAgent');
goog.module.declareLegacyNamespace();

(function() {
  window.UserAgent = {};

  // Copied from closure library to detect IE versions
  var isIE = function() {
    var ua = window.navigator.userAgent;
    return !(ua.indexOf('Opera') == 0) && ua.indexOf('MSIE') != -1;
  };
  window.UserAgent.isIE = isIE();

  var getIEVersion = function() {
    if (window.UserAgent.isIE) {
      var ua = window.navigator.userAgent;
      var version = (/MSIE\s+([^\);]+)(\)|;)/.exec(ua))[1];
      // Use document mode if it's greater than the useragent version
      var docMode = window.document.documentMode;
      if (docMode > parseFloat(version)) {
        version = docMode;
      }
      return version;
    }
    return null;
  };
  window.UserAgent.ieVersion = getIEVersion();

  var isIEVersionOrBelow = function(version) {
    return window.UserAgent.ieVersion != null && window.UserAgent.ieVersion <= version;
  };
  window.UserAgent.isIEVersionOrBelow = isIEVersionOrBelow;

  var isIEVersionOrAbove = function(version) {
    return window.UserAgent.ieVersion != null && window.UserAgent.ieVersion >= version;
  };
  window.UserAgent.isIEVersionOrAbove = isIEVersionOrAbove;

  var isIE7OrBelow = function() {
    return isIEVersionOrBelow(7.0);
  };
  window.UserAgent.isIE7OrBelow = isIE7OrBelow;
})();

