/**
 * @fileoverview Retrieves Pages Scan data from backend
 *
 * @author Oscar Li (oscar@yext.com)
 */
goog.provide('yext.locationscan_common.pagesscan');

yext.locationscan_common.pagesscan.MAX_ATTEMPTS = 300;

/**
 * @constructor
 * @param {number} pagesScanId
 * @param {function()} successCallback
 * @param {function()} errorCallback
 */
yext.locationscan_common.pagesscan.PagesScan = function(pagesScanId, appsPartnerId, inputLocation, successCallback, errorCallback) {
  /**
   * @type {number}
   * @private
   */
  this.pagesScanId_ = pagesScanId;

  /**
   * @type {number}
   * @private
   */
  this.appsPartnerId_ = appsPartnerId;

  /**
   * @type {number}
   * @private
   */
  this.inputLocation_ = inputLocation;

  /**
   * @type {function()}
   * @private
   */
  this.successCallback_ = successCallback;

  /**
   * @type {function()}
   * @private
   */
  this.errorCallback_ = errorCallback;

  /**
   * @type {number}
   * @private
   */
  this.attempts_ = 0;
};

yext.locationscan_common.pagesscan.PagesScan.prototype.run = function() {
  if (this.attempts_ >= yext.locationscan_common.pagesscan.MAX_ATTEMPTS) {
    this.errorCallback_();
    return;
  }

  $.ajax({
    url: '/partner/api/pagesScanResults',
    type: 'GET',
    data: {'pagesScanId': this.pagesScanId_,
      'appsPartnerId': this.appsPartnerId_,
      'inputLocation': JSON.stringify(this.inputLocation_)},
    success: function(response) {
      var pagesScanStatus = response['status'];
      var schemaInsufficientDataPercentage = response['schemaInsufficientDataPercentage'];

      if (pagesScanStatus === 'UNFINISHED_SCAN') {
        this.attempts_++;
        setTimeout(this.run.bind(this), 2000);
        return;
      } else if (pagesScanStatus === 'ERROR'
                 || pagesScanStatus === 'INVALID_SCAN'
                 || schemaInsufficientDataPercentage === 100) {
        this.errorCallback_();
        return;
      }
      this.successCallback_(response);
    }.bind(this),
    error: function(response) {
      this.errorCallback_();
    },
  });
};
