goog.provide('yext.locationscan_common.publisherscan');

yext.locationscan_common.publisherscan.SCAN_THREADS = 3;

/**
 * @constructor
 */
yext.locationscan_common.publisherscan.ScanCandidate = function(partnerId, brandId) {
  this.partnerId = partnerId;
  this.brandId = brandId;
};

/**
 * @constructor
 */
yext.locationscan_common.publisherscan.LongPollScan = function(
  candidates,
  location,
  locationId,
  appVersion,
  appsPartnerId,
  calculateBaseYextCategoryId) {
  this.candidates = candidates;
  this.locationString = JSON.stringify(location);
  this.locationId = locationId;
  this.appVersion = appVersion;
  this.appsPartnerId = appsPartnerId;
  this.unscannedCandidates = candidates.slice();
  this.scanAttempts = 0;
  this.calculateBaseYextCategoryId = calculateBaseYextCategoryId;

  this.results = [];
};

yext.locationscan_common.publisherscan.LongPollScan.prototype.run = function() {
  var self = this;

  var data = {
    'appsPartnerId': this.appsPartnerId,
    'candidates': JSON.stringify(this.candidatesForRequest(this.candidates)),
    'location': this.locationString,
    'locationId': this.locationId,
    'calculateBaseYextCategoryId': this.calculateBaseYextCategoryId,
  };

  var url;
  if (this.isLocationScan()) {
    url = '/locationscan' + this.getAppVersion() + '/asyncPublisherScan';
  } else {
    url = '/partner/api/asyncPublisherScan';
  }

  $.ajax({
    url: url,
    type: 'POST',
    data: data,
    success: function(response) {
      self.id = response['id'];
      self.hasBaseYextCategoryId = (response['hasBaseYextCategoryId'] === 'true');
      self.longPoll();
    },
  });
};

yext.locationscan_common.publisherscan.LongPollScan.prototype.candidatesForRequest = function(candidates) {
  var forRequest = [];
  candidates.forEach(function(candidate) {
    forRequest.push([candidate.partnerId, candidate.brandId]);
  });
  return forRequest;
};

yext.locationscan_common.publisherscan.LongPollScan.prototype.longPoll = function() {
  if (this.scanAttempts > this.candidates.length * 2) {
    return;
  }

  if (this.unscannedCandidates.length) {
    var self = this;

    var data = {
      'id': this.id,
      'candidates': JSON.stringify(this.candidatesForRequest(this.unscannedCandidates)),
    };

    var url;
    if (this.isLocationScan()) {
      url = '/locationscan' + this.getAppVersion() + '/asyncPublisherScanResults';
    } else {
      url = '/partner/api/asyncPublisherScanResults';
    }

    $.ajax({
      url: url,
      type: 'POST',
      data: data,
      success: function(response) {
        if (!response['error']) {
          var reports = response['reports'];
          var otherResponseFields = {};
          otherResponseFields['reviewScorePercentile'] = response['reviewScorePercentile'];
          otherResponseFields['reviewCountPercentile'] = response['reviewCountPercentile'];
          otherResponseFields['websitePartners'] = response['websiteFieldPartners'];
          otherResponseFields['hoursPartners'] = response['hoursFieldPartners'];
          otherResponseFields['categoryPartners'] = response['categoryFieldPartners'];
          self.results = self.results.concat(reports);
          self.scansCompleteCallback(reports, otherResponseFields);
          for (var i = 0; i < reports.length; i++) {
            var report = reports[i];
            for (var j = self.unscannedCandidates.length - 1; j >= 0; j--) {
              var candidate = self.unscannedCandidates[j];
              if (report['partnerId'] == candidate.partnerId && report['brandId'] == candidate.brandId) {
                self.unscannedCandidates.splice(j, 1); // remove
              }
            }
          }
        }
        self.longPoll();
      },
      error: function() {
        self.longPoll();
      },
      timeout: 11000,
    });

    this.scanAttempts++;
  } else {
    this.scanCompleteCallback();
  }
};

yext.locationscan_common.publisherscan.LongPollScan.prototype.isLocationScan = function() {
  if (this.appVersion) {
    return true;
  } else {
    return false;
  }
};

yext.locationscan_common.publisherscan.LongPollScan.prototype.getAppVersion = function() {
  var version = '';
  if (this.appVersion) {
    version += '/v' + parseInt(this.appVersion);
  }
  return version;
};

yext.locationscan_common.publisherscan.LongPollScan.prototype.onScansCompleteCallback = function(cb) {
  this.scansCompleteCallback = cb;
};

yext.locationscan_common.publisherscan.LongPollScan.prototype.onScanComplete = function(cb) {
  this.scanCompleteCallback = cb;
};
